import Vue from 'vue'
import VueRouter from 'vue-router'
import Shouye from '@/view/shouye'
import Damaoqi from '@/view/damaoqi'
import Xinwen from '@/view/xinwen'
import Guanyu from '@/view/guanyuwomen'
import Qiye from '@/view/qiye'
import Ruzhu from '@/view/ruzhu'
import Jianyi from '@/view/jianyi'
import Zhuisu from '@/view/zhuisu'
import XinwemVal from '@/view/xinwen_val'
import QiyeVal from '@/view/qiye_val'
import Gongxu from "@/view/gongxu"
//农业
import diqu from '@/view/ny_diqu'
// import jianyi from '@/view/ny_jianyi'
import jigou from '@/view/ny_jigou'
import qiye from '@/view/ny_qiye'
import zhishi from '@/view/ny_zhishi'
import zhengce from '@/view/ny_zhengce'
import zixun from '@/view/ny_zixun'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    component: Shouye
  },
  // 农业
  {
    path: "/diqu",
    name: "diqu",
    component: diqu
  },
  // {
  //   path: "/jianyi",
  //   name: "jianyi",
  //   component: jianyi
  // },
  {
    path: "/jigou",
    name: "jigou",
    component: jigou
  },
  {
    path: "/qiye",
    name: "qiye",
    component: qiye
  },
  {
    path: "/zhishi",
    name: "zhishi",
    component: zhishi
  },
  {
    path: "/zhengce",
    name: "zhengce",
    component: zhengce
  },
  {
    path: "/zixun",
    name: "zixun",
    component: zixun
  },




  {
    path: "/damaoqi",
    component: Damaoqi
  },
  {
    path: "/xinwen",
    component: Xinwen
  },
  {
    path: "/XinwenVal",
    component: XinwemVal
  },
  {
    path: "/guanyu",
    component: Guanyu
  },
  {
    path: "/qiye",
    component: Qiye
  },
  {
    path: "/QiyeVal",
    component: QiyeVal
  },
  {
    path: "/ruzhu",
    component: Ruzhu
  },
  {
    path: "/jianyi",
    component: Jianyi
  },
  {
    path: "/zhuisu",
    name: "zhuisu",
    component: Zhuisu
  },
  {
    path: "/gongxu",
    name: "gongxu",
    component: Gongxu
  }
]
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})


export default router
