<template>
  <div class="concent1">
    <Caidan></Caidan>
    <div class="banner">
      <img src="../assets/damaoqi/21.png"
           alt="">
    </div>
    <div class="jianjie">
      <img src="../assets/guanyu/1.png"
           alt="">
      <p style="">达茂旗农畜产品(食品)质量安全追溯服务平台为落实国务院加快应用现代信息技术建
        设重要产品追溯体系的重大部署，充分利用物联网技术，率先在农牧行
        业建立全国统一质量追溯平台。 消费者可以通过平台进行追溯查询
        、享受在线农技服务及查询优质农资商品；农资流通企业可利用平台提
        供的数据共享与集成机制，实现上、下游产业链环节的数据获取，从而为
        企业的经营生产提供决策支撑。政府监管部门可依托平台对农资流通进行监管，从而保障农牧市场安全。</p>
    </div>
    <!-- <div class="banner">
           <img src="../assets/damaoqi/21.png" alt="">
       </div> -->
    <div class="tese">
      <img src="../assets/damaoqi/30.png"
           alt="">
    </div>
    <div class="imgs1">
      <img src="../assets/damaoqi/31.png"
           alt="">
      <img src="../assets/damaoqi/32.png"
           alt="">
      <img src="../assets/damaoqi/33.png"
           alt="">
      <img src="../assets/damaoqi/34.png"
           alt="">
      <img src="../assets/damaoqi/35.png"
           alt="">
      <img src="../assets/damaoqi/36.png"
           alt="">
      <img src="../assets/damaoqi/37.png"
           alt="">
      <img src="../assets/damaoqi/38.png"
           alt="">
    </div>
    <div class="tese">
      <img src="../assets/damaoqi/1.png"
           alt="">
    </div>
    <div class="imgs">
      <img src="../assets/damaoqi/2.png"
           alt="">
      <img src="../assets/damaoqi/3.png"
           alt="">
      <img src="../assets/damaoqi/4.png"
           alt="">
      <img src="../assets/damaoqi/5.png"
           alt="">
      <img src="../assets/damaoqi/6.png"
           alt="">
      <img src="../assets/damaoqi/7.png"
           alt="">
      <img src="../assets/damaoqi/8.png"
           alt="">
      <img src="../assets/damaoqi/9.png"
           alt="">
    </div>
    <div class="tslogo">
      <img src="../assets/damaoqi/17.png"
           alt="">
    </div>
    <div class="ncp">
      <div @click="xumoye"
           class="tsncp">
        <img src="../assets/damaoqi/16-1.png"
             alt="">
      </div>
      <div @click="nongye"
           class="tsxmy">
        <img src="../assets/damaoqi/18.png"
             alt="">
      </div>

    </div>
    <div class="xmye">
      <div class="conter"
           id="content"
           v-if=show1>
        <div class="tudou">
          <img style="width:300px;height:200px"
               src="../assets/image/140.jpg"
               alt="">
          <div>
            <p>达茂草原羊</p>
            <div>达茂草原羊，作为达茂旗特产，就是这种让你吃一口就忘不了的羊肉。
              羊天生耐寒适合内蒙古高原的寒冷气候，所以羊肉补体虚，祛寒冷，温补气
              血；益肾气，补形衰，开胃健力；补益产妇，通乳治带，助元阳，益精血。</div>
          </div>
        </div>

        <div class="tudou">
          <img style="width:300px;height:200px"
               src="../assets/image/142.jpg"
               alt="">
          <div>
            <p>小肉牛</p>
            <div>肉牛即肉用牛，是一类以生产牛肉为主的牛。
              特点是体躯丰满、增重快、饲料利用率高、
              产肉性能好，肉质口感好。肉牛不仅为人们提供肉用品，
              还为人们提供其他副食品。肉牛养殖的前景广阔。
              供宰杀食用的肉牛，有西门塔尔牛、夏洛莱牛、利木赞牛等优良品种。</div>
          </div>
        </div>

        <div class="tudou">
          <img style="width:300px;height:200px;"
               src="../assets/image/141.jpg"
               alt="">
          <div>
            <p>幸福香猪</p>
            <div>“幸福香猪”如约登陆达茂旗石宝镇，该项目主要以“公司+基地+贫困户”的模式，
              养殖的是包头市草原立新农牧业公司自行培育的“浓香源”优质四元杂交香猪。
              有效助力村集体经济增长，拓宽贫困户就业渠道。 </div>
          </div>

        </div>

      </div>
      <div class="conter"
           v-if=show>
        <div class="tudou">
          <img style="width:300px; "
               src="../assets/damaoqi/15.png"
               alt="">
          <div>
            <p>达茂旗土豆</p>
            <div>达茂旗位于阴山之北，雨热同季、降水少而集中，日照长、光照足具备种植马铃薯的优越气候环境，土壤又多为栗钙土和沙壤土，是马铃薯喜好生长的土地，且土壤、水源和大气均无污染。</div>
          </div>

        </div>
        <div class="tudou">
          <img style="width:300px; "
               src="../assets/damaoqi/19.png"
               alt="">
          <div>
            <p>达茂旗小红皮麦</p>
            <div>达茂旗小红皮小麦具有面粉劲道、口感好、麦香浓郁、营养成分含量高、品质好等特点。国家工商总局商标局发布注册公告，批注“达茂小红皮小麦”注册为地理标志证明商标。</div>
          </div>
        </div>
        <div class="tudou">
          <img style="width:300px;"
               src="../assets/damaoqi/20.png"
               alt="">
          <div>
            <p>达茂旗绿头蒜</p>
            <div>达茂旗小文公大蒜因其味辛辣、蒜头呈嫩绿色而出名，又俗称为“绿头蒜”。达茂旗小文公乡以发展旱作农业为主，其空气、水质、土壤零污染，因独特的自然条件孕育，其种植的大蒜蒜头大、营养物质含量高、品质好而深受消费者的青睐。</div>
          </div>
        </div>
      </div>
    </div>
    <div class="beijing">
      <div class="xinxi">
        <div>
          <img src="../assets/logo/1.png"
               alt="">
          <p style="font-size:12px;margin-left: 10px;">地址:达茂旗</p>
        </div>
        <div>
          <img src="../assets/logo/2.png"
               alt="">
          <p style="font-size:12px;margin-left: 10px;">电话:0472-8422815</p>
        </div>
        <div>
          <img src="../assets/logo/3.png"
               alt="">
          <p style="font-size:12px;margin-left: 10px;">邮编:0145000</p>
        </div>
        <div>
          <img src="../assets/logo/4.png"
               alt="">
          <p style="font-size:12px;margin-left: 10px;">邮箱:dmqnxcpjgzx@163.com</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import axios from "axios"
// import Caidan from '../components/caidan'
export default {
  // components: {
  //     Caidan
  // },
  data () {
    return {
      show: false,
      show1: true,
      info: []
    };
  },
  mounted: function () {
    this.getshuju()
    this.getshuju1()
  },
  methods: {
    nongye: function () {
      console.log("点击了农业")
      this.show = true,
        this.show1 = false

    },
    xumoye: function () {
      console.log("点击了畜牧业")

      this.show = false,
        this.show1 = true
    }
  }
}
</script>

<style scoped>
/* *{
    margin: 0;
    padding: 0;
} */
.banner {
  width: 100%;
  height: 600px;
}
.banner img {
  width: 100%;
  height: 100%;
}
.jianjie {
  width: 1090px;
  height: 200px;
  margin: 0 auto;
  position: relative;
  margin-top: 20px;
}
.jianjie img {
  position: absolute;
  top: 0px;
  left: 35%;
}
.jianjie p {
  position: absolute;
  top: 30%;
  font-size: 14px;
  color: #797979;
}
.concent1 {
  position: relative;
}
.pingtai {
  width: 1;
  height: 100px;
  position: relative;
}
.herder {
  font-size: 30px;
  color: #71ae47;
  position: absolute;
  top: 38px;
  left: 190px;
}
.caidan {
  position: absolute;
  top: 80px;
  right: 110px;
}
.caidan div {
  display: inline;
  padding-left: 30px;
  color: #009966;
}
.el-menu-demo {
  display: flex;
  justify-content: center;
}
.el-menu-item {
  font-size: 20px;
  width: 200px;
  height: 50px;
}
.banner {
  width: 100%;
  height: 600px;
  /* border: 1px solid #333; */
}
.banner img {
  width: 100%;
  height: 100%;
}
.tese {
  width: 1090px;
  height: 100px;
  margin: 50px auto 0;
}
.imgs {
  width: 1090px;
  height: 420px;
  margin: 0 auto;
}
.imgs1 {
  width: 1090px;
  height: 420px;
  margin: 0 auto;
}
.imgs img {
  width: 250px;
  height: 200px;
  padding-left: 15px;
  padding-top: 10px;
}
.imgs1 img:nth-child(1) {
  width: 250px;
  height: 100px;
  padding-left: 15px;
  padding-top: 10px;
}
.imgs1 img:nth-child(2) {
  width: 250px;
  height: 200px;
  padding-left: 15px;
  padding-top: 10px;
}
.imgs1 img:nth-child(3) {
  width: 250px;
  height: 200px;
  padding-left: 15px;
  padding-top: 10px;
}
.imgs1 img:nth-child(4) {
  width: 200px;
  height: 200px;
  padding-left: 15px;
  padding-top: 10px;
}
.imgs1 img:nth-child(5) {
  width: 80px;
  height: 200px;
  padding-left: 15px;
  padding-top: 10px;
  padding-right: 50px;
  padding-left: 110px;
}
.imgs1 img:nth-child(6) {
  width: 250px;
  height: 200px;
  padding-left: 15px;
  padding-top: 10px;
}

.imgs1 img:nth-child(7) {
  width: 250px;
  height: 200px;
  padding-left: 15px;
  padding-top: 10px;
}
.imgs1 img:nth-child(8) {
  width: 250px;
  height: 200px;
  padding-left: 15px;
  padding-top: 10px;
}

.imgs img:hover {
  opacity: 0.3;
}
.imgs1 img:hover {
  opacity: 0.3;
}
.text {
  width: 1090px;
  height: 420px;
  border: 1px solid #333;
  position: relative;
  top: -270px;
  left: 200px;
}
.text div {
  width: 295px;
  height: 200px;
  border: 1px solid #333;
  float: left;
  text-align: center;
  line-height: 200px;
  color: #ffffff;
  display: none;
}
.text div:hover {
  display: block;
  opacity: 0.4;
}

.tslogo {
  width: 1090px;
  height: 100px;
  margin: 50px auto 0;
}
/* 特色农产品 */
.ncp {
  width: 1090px;
  height: 200px;
  margin: 0 auto;
}
.tsncp {
  width: 545px;
  height: 200px;
  float: left;
}
.tsncp img {
  width: 240px;
  height: 100px;
  margin-left: 150px;
}
.conter {
  width: 1090px;
  height: 330px;
}
.tudou {
  width: 360px;
  height: 330px;
  float: left;
}
.tudou div p {
  margin-left: 100px;
}
.tudou > div > div {
  width: 300px;
  height: 100px;
  font-size: 14px;
  margin-left: 10px;
  color: grey;
}
.xmye {
  width: 1090px;
  height: 360px;
  margin: 0 auto;
}
.tsxmy {
  width: 540px;
  height: 200px;
  float: left;
}
.tsxmy img {
  width: 240px;
  height: 100px;
  margin-left: 100px;
}
.beijing {
  width: 100%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  background-image: url("../assets/image/165.png");
  background-size: 100% 100%;
}
.banquan {
  width: 120px;
  height: 20px;
  margin: 0 auto;
}
</style>