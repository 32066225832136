<template>
  <div class="concent1">
    <!-- <Caidan></Caidan> -->
    <div class="banner">
      <img src="../assets/qiye/24-1.png" alt="">
    </div>
    <div class="qiye">
      <img src="../assets/qiye/25_1.png" alt="">
      <div>
        <el-col :span="12">
          <el-menu default-active="1" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose"
            :unique-opened="true">
            <el-submenu index="1" class="el-submenu" :unique-opened="true">
              <template slot="title">
                <i class="el-icon-location"></i>
                <span>监管机构</span>
              </template>
              <el-menu-item-group class="el-submenu">
                <el-menu-item @click="isshow" index="0">乡村产业发展司</el-menu-item>
                <el-menu-item @click="isshow" index="1">农村社会事业促进司</el-menu-item>
                <el-menu-item @click="isshow" index="2">农村合作经济指导司</el-menu-item>
                <el-menu-item @click="isshow" index="3">市场与信息化司</el-menu-item>
                <el-menu-item @click="isshow" index="4">国际合作司</el-menu-item>
                <el-menu-item @click="isshow" index="5">科技教育司</el-menu-item>
                <el-menu-item @click="isshow" index="6">农产品质量安全监管司</el-menu-item>
                <el-menu-item @click="isshow" index="7">种植业管理司</el-menu-item>
                <el-menu-item @click="isshow" index="8">畜牧兽医局</el-menu-item>
                <el-menu-item @click="isshow" index="9">农垦局</el-menu-item>
                <el-menu-item @click="isshow" index="10">种业管理局</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="2">
              <template slot="title">
                <i class="el-icon-location"></i>
                <span>内蒙古自治区监管机构</span>
              </template>
              <el-menu-item-group class="el-submenu">
                <!-- <el-menu-item @click="isshow"  style="font-size:12px;" index="11">内蒙古蓝色牧野肉业股份有限公司</el-menu-item> -->
                <el-menu-item @click="isshow" index="0">乡村产业发展司</el-menu-item>
                <el-menu-item @click="isshow" index="1">农村社会事业促进司</el-menu-item>
                <el-menu-item @click="isshow" index="2">农村合作经济指导司</el-menu-item>
                <el-menu-item @click="isshow" index="3">市场与信息化司</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="3">
              <template slot="title">
                <i class="el-icon-location"></i>
                <span>包头市监管机构</span>
              </template>
              <el-menu-item-group class="el-submenu">
                <!-- <el-menu-item @click="isshow"  style="font-size:12px;" index="11">内蒙古蓝色牧野肉业股份有限公司</el-menu-item> -->
                <el-menu-item @click="isshow" index="4">国际合作司</el-menu-item>
                <el-menu-item @click="isshow" index="5">科技教育司</el-menu-item>
                <el-menu-item @click="isshow" index="6">农产品质量安全监管司</el-menu-item>
              </el-menu-item-group>

            </el-submenu>
            <el-submenu index="4">
              <template slot="title">
                <i class="el-icon-location"></i>
                <span>监管机构</span>
              </template>
              <el-menu-item-group class="el-submenu">
                <!-- <el-menu-item @click="isshow"  style="font-size:12px;" index="11">内蒙古蓝色牧野肉业股份有限公司</el-menu-item> -->
                <el-menu-item @click="isshow" index="7">种植业管理司</el-menu-item>
                <el-menu-item @click="isshow" index="8">畜牧兽医局</el-menu-item>
                <el-menu-item @click="isshow" index="9">农垦局</el-menu-item>
              </el-menu-item-group>

            </el-submenu>
          </el-menu>
        </el-col>
      </div>
      <div class="xiangxi" v-for="item in pageinfo" :key="item.index">
        <div v-if="item.isshow" class="xiangxi1">
          <div class="chanye">{{ item.name1 }}</div>
          <div class="dmq">临南农业平台&nbsp;
            &nbsp;4月28日
          </div>
          <img :src=item.url alt="">
          <div class="jieshao">{{ item.jieshao }}</div>
          <div class="lingdao">现任领导:</div>
          <div class="name">{{ item.boss[0].name }}</div>
          <a class="lianjie" :href=item.href>{{ item.href }}</a>
        </div>

      </div>
    </div>
    <div class="beijing">
      <div class="xinxi">
        <div>
          <img src="../assets/logo/1.png" alt="">
          <p style="font-size:12px;margin-left: 10px;">地址:山东省德州市临邑县临南镇</p>
        </div>
        <div>
          <img src="../assets/logo/2.png" alt="">
          <p style="font-size:12px;margin-left: 10px;">电话:0534-4710111</p>
        </div>
        <div>
          <img src="../assets/logo/3.png" alt="">
          <p style="font-size:12px;margin-left: 10px;">邮编:251500</p>
        </div>
        <div>
          <img src="../assets/logo/4.png" alt="">
          <p style="font-size:12px;margin-left: 10px;">邮箱:lnzrmzf@dz.shandong.cn</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageinfo: [
        {
          index: 1,
          name1: "乡村发展司",
          url: require("../assets/qiye/1.png"),
          href: "http://www.xccys.moa.gov.cn/",
          isshow: false,
          jieshao: "中华人民工会和国农业农村部乡村产业发展司是中华人民共和国农业农业农村部的下属机构",
          boss: [
            {
              name: "邵建成:农业农村部乡村产业发展司副司长(2019年11月)",
              position: "农业农村部乡村产业发展司副司长(2019年11月)",
            },
            {
              name: "吴晓玲:农业农村部乡村产业发展司一级巡视员。",
              position: "农业农村部乡村产业发展司一级巡视员。"
            }
          ]
        },
        {
          index: 2,
          name1: "农村社会事业促进司",
          url: require("../assets/qiye/2.png"),
          href: "http://www.shsys.moa.gov.cn/",
          isshow: true,
          jieshao: "中华人民共和国农业农村部农村社会事业促进司是中华人民共和国农业农村部内设机构。",
          boss: [
            {
              name: "现任领导：杨春华，农业农村部农村社会事业促进司二级巡视员。",
              position: "农业农村部乡村产业发展司副司长(2019年11月)"
            },
            {
              name: "吴晓玲",
              position: "农业农村部乡村产业发展司一级巡视员。"
            }
          ]
        },
        {
          index: 3,
          name1: "农村经济合作指导司",
          url: require("../assets/qiye/3.png"),
          href: "hzjjs.moa.gov.cn",
          isshow: false,
          jieshao: "中华人民共和国农业农村部农村合作经济指导司是中华人民共和国农业农村部的内设机构。",
          boss: [
            {
              name: "现任领导：赵铁桥：农业农村部农村合作经济指导司一级巡视员。 ",
              position: "农业农村部乡村产业发展司副司长(2019年11月)"
            },
            {
              name: "吴晓玲",
              position: "农业农村部乡村产业发展司一级巡视员。"
            }
          ]
        },
        {
          index: 4,
          name1: "市场与信息化司",
          url: require("../assets/qiye/4.png"),
          href: "http://www.scs.moa.gov.cn/",
          isshow: false,
          jieshao: "拟订农产品和农业生产资料市场体系建设规划、计划并组织实施；提出农产品和农业生产资料供求、流通及价格政策建议；提出促进农产品流通发展的政策措施。",
          boss: [
            {
              name: "张合成",
              position: "农业农村部乡村产业发展司副司长(2019年11月)"
            },
            {
              name: "吴晓玲",
              position: "农业农村部乡村产业发展司一级巡视员。"
            }
          ]
        },
        {
          index: 5,
          name1: "国际合作司",
          url: require("../assets/qiye/5.png"),
          href: "http://www.gjs.moa.gov.cn/",
          jieshao: "组织开展新闻出版广播影视和著作权的对外及港澳台交流与合作。",
          isshow: false,
          boss: [
            {
              name: "孟宪林",
              position: "农业农村部乡村产业发展司副司长(2019年11月)"
            },
            {
              name: "吴晓玲",
              position: "农业农村部乡村产业发展司一级巡视员。"
            }
          ]
        },
        {
          index: 6,
          namne1: "科技教育司",
          url: require("../assets/qiye/6.png"),
          href: "http://www.kjs.moa.gov.cn/",
          isshow: false,
          jieshao: "承担推动农业科技体制改革及相关体系建设、科研、技术引进、成果转化和技术推广工作。",
          boss: [
            {
              name: "孙若风",
              position: "农业农村部乡村产业发展司副司长(2019年11月)"
            },
            {
              name: "吴晓玲",
              position: "农业农村部乡村产业发展司一级巡视员。"
            }
          ]
        },
        {
          index: 7,
          name1: "农产品质量安全监管司",
          url: require("../assets/qiye/7.png"),
          href: "http://www.jgj.moa.gov.cn/",
          isshow: false,
          jieshao: "中华人民共和国农业农村部农产品质量安全监管司是中华人民共和国农业农村部的内设机构。",
          boss: [
            {
              name: "黄修柱(副司长)",
              position: "农业农村部乡村产业发展司副司长(2019年11月)"
            },
            {
              name: "吴晓玲",
              position: "农业农村部乡村产业发展司一级巡视员。"
            }
          ]
        },
        {
          index: 8,
          name1: "种植业管理司",
          url: require("../assets/qiye/8.png"),
          href: "http://www.zzys.moa.gov.cn/",
          isshow: false,
          jieshao: "中华人民共和国农业部种植业管理司是属于农业部的部门，主要负责负责种植业的行业管理。",
          boss: [
            {
              name: "刘莉华(副司长)",
              position: "农业农村部乡村产业发展司副司长(2019年11月)"
            },
            {
              name: "吴晓玲",
              position: "农业农村部乡村产业发展司一级巡视员。"
            }
          ]
        },
        {
          index: 9,
          name1: "畜牧兽医局",
          url: require("../assets/qiye/9.png"),
          href: "http://www.xmsyj.moa.gov.cn/",
          isshow: false,
          jieshao: "贯彻落实畜牧兽医的法律法规、方针政策、规划和计划。归属农业局。县级及以上行政规划城市设立。",
          boss: [
            {
              name: "暂无数据",
              position: "农业农村部乡村产业发展司副司长(2019年11月)"
            },
            {
              name: "吴晓玲",
              position: "农业农村部乡村产业发展司一级巡视员。"
            }
          ]
        },
        {
          index: 10,
          name1: "农垦局",
          url: require("../assets/qiye/10.png"),
          href: "http://www.nkj.moa.gov.cn/",
          isshow: false,
          jieshao: "农业部农垦局是全国农垦系统的主管部门，也是热作行业的主管部门。",
          boss: [
            {
              name: "王润雷(副司长)",
              position: "农业农村部乡村产业发展司副司长(2019年11月)"
            },
            {
              name: "吴晓玲",
              position: "农业农村部乡村产业发展司一级巡视员。"
            }
          ]
        },
        {
          index: 11,
          name1: "种植业管理局",
          url: require("../assets/qiye/11.png"),
          href: "http://www.zzj.moa.gov.cn/",
          isshow: false,
          jieshao: "中华人民共和国农业农村部种业管理司是中华人民共和国农业农村部的内设机构。",
          boss: [
            {
              name: "周云龙，农业农村部种业管理司一级巡视员。",
              position: "农业农村部乡村产业发展司副司长(2019年11月)"
            },
            {
              name: "吴晓玲",
              position: "农业农村部乡村产业发展司一级巡视员。"
            }
          ]
        }
        // {
        //     index:12,
        //     name1:"内蒙古蓝色牧野肉业股份有限公司",
        //     url:require("../assets/qiye/12.png"),
        //     href:"http://www.bjchc.com.cn/style/011531206.html",
        //     isshow:false,
        //     jieshao:"内蒙古蓝色牧野肉业股份有限公司成立于2012年06月18日，注册地位于内蒙古自治区包头市达茂旗百灵庙镇8公里处",
        //     boss:[
        //         {
        //              name:"贾德新(法定代表人)",
        //              position:"农业农村部乡村产业发展司副司长(2019年11月)"
        //         },
        //         {
        //             name:"吴晓玲",
        //             position:"农业农村部乡村产业发展司一级巡视员。"
        //         }
        //     ]
        // }
      ]
    }
  },
  methods: {
    isshow: function (e) {
      console.log(e.index)
      var a = e.index  // eslint-disable-line no-unused-vars
      for (var i = 0; i <= 11; i++) {
        if (i != a) {
          this.pageinfo[i].isshow = false
        } else {
          this.pageinfo[a].isshow = true
        }
      }
    }
  }
}
</script>

<style scoped>
.concent1 {
  background-color: #fff;
}

.el-menu-demo {
  display: flex;
  justify-content: center;
}

.banner img {
  width: 1200px;
  max-height: 300px;
  display: block;
  margin: 0 auto;
}

.qiye {
  width: 919px;
  height: 850px;
  margin: 0 auto;
  position: relative;
  /* background-color: #f0f0f0; */
}

.qiye>img {
  position: absolute;
  top: 50px;
}

.el-menu-vertical-demo {
  position: absolute;
  top: 100px;
  /* background-color: #f9f9f9; */
}

.el-submenu {
  background-color: #f9f9f9;
}

/* .el-menu{
       background-color: #f9f9f9;
   } */
.el-menu-item {
  width: 250px;
  font-size: 12px;
}

.xiangxi1 {
  width: 660px;
  height: 675px;
  background-color: #f9f9f9;
}

.xiangxi {
  position: relative;
  left: 300px;
  top: 80px;
  background: #ffffff;
}

.xiangxi img {
  position: absolute;
  top: 120px;
  width: 610px;
}

.chanye {
  position: absolute;
  top: 30px;
  left: 300px;
}

.dmq {
  position: absolute;
  top: 70px;
  left: 260px;
  font-size: 12px;
  color: #576b95;
}

.jieshao {
  width: 550px;
  height: 40px;
  /* border: 1px solid #333; */
  position: absolute;
  top: 300px;
  left: 15px;
  font-size: 14px;
  color: #7a7a7a;
}

.lingdao {
  position: absolute;
  top: 350px;
  left: 100px;
}

.name {
  position: absolute;
  top: 380px;
  left: 100px;
}

.name1 {
  position: absolute;
  top: 390px;
  left: 100px;
}

.lianjie {
  position: absolute;
  top: 420px;
  left: 100px;
}

.beijing {
  width: 100%;
  height: 50px;
  /* background-image: url("../assets/image/165.png"); */
  background-size: 100% 100%;
  background-color: #52bb9d;

  display: flex;
  align-items: center;
}

/* .beijing .xinxi{
     width: 1000px;
     height: 25px;
     position: absolute;
     left: 16%;
     display: flex;
 } */
.xinxi div {
  flex: 1;
}

.xinxi div img {
  float: left;
  margin-top: 3px;
}

.xinxi div p {
  float: left;
  line-height: 0px;
  color: #ffffff;
}

/* .banquan{
     font-size: 12px;
     color: #ffffff;
     position: absolute;
     top: 20px;left: 43%;
 } */
</style>
