<template>
  <div class="concent1">
    <Caidan></Caidan>
    <div class="banner">
      <img src="../assets/zu22.png" alt="">
    </div>
    <!-- <div class="xinwen">
      <img style="margin-top:15px" src="../assets/xinwen/1.png" alt="">
    </div> -->
    <div class="tablist">
      <el-row>
        <el-col :span="6">
          <el-menu class="el-menu-vertical-demo" @select="handleSelect" unique-opened>
            <el-submenu :index="menu.id" v-for="menu in dataList" :key="menu.id">
              <template slot="title">
                <span>{{ menu.name }}</span>
              </template>
              <el-menu-item v-for="article in menu.articles" :key="article.article_id" :index="article.article_id">{{
                article.article_name }}</el-menu-item>
            </el-submenu>
          </el-menu>
        </el-col>
        <el-col :span="18">
          <div class="content">
            <div v-html="articles.content"></div>
          </div>
        </el-col>
      </el-row>

    </div>

    <div class="beijing">
      <div class="xinxi">
        <div>
          <img src="../assets/logo/1.png" alt="">
          <p style="font-size:12px;margin-left: 10px;">地址:山东省德州市临邑县临南镇</p>
        </div>
        <div>
          <img src="../assets/logo/2.png" alt="">
          <p style="font-size:12px;margin-left: 10px;">电话:0534-4710111</p>
        </div>
        <div>
          <img src="../assets/logo/3.png" alt="">
          <p style="font-size:12px;margin-left: 10px;">邮编:251500</p>
        </div>
        <div>
          <img src="../assets/logo/4.png" alt="">
          <p style="font-size:12px;margin-left: 10px;">邮箱:lnzrmzf@dz.shandong.cn</p>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      tabPosition: 'left',
      dataList: [],
      articles: {}
    };
  },
  mounted: function () {
    this.getCategories()
  },
  methods: {
    getCategories() {
      let that = this
      that.$http.get('/pages/article-categories?category_type=' + 'HELP')
        .then(function (response) {
          that.dataList = response.data.children
          console.log('------------------this.dataList', that.dataList);
        })
        .catch(function (error) {
          console.log(error, "出错");
        });
    },

    //获取文章详情
    getArticles(id) {
      let that = this
      that.$http.get('/pages/articles/' + id)
        .then(function (response) {
          that.articles = response.data
          console.log('------------------t详情t', that.articles);
        })
        .catch(function (error) {
          console.log(error, "出错");
        });
    },

    handleSelect(key) {
      this.getArticles(key)
    },
  }
}
</script>

<style scoped>
.pingtai {
  width: 1;
  height: 100px;
  position: relative;
}

.herder {
  font-size: 30px;
  color: #71ae47;
  position: absolute;
  top: 38px;
  left: 190px;
}

.caidan {
  position: absolute;
  top: 80px;
  right: 50px;
}

.caidan div {
  display: inline;
  padding-left: 30px;
  color: #009966;
}

.banner img {
  width: 100%;
  height: 600px;
}

.xinwen {
  width: 1090px;
  height: 80px;
  margin: 0 auto;
}

.beijing {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  background-image: url("../assets/image/165.png");
  background-size: 100% 100%;
}

.xinxi div {
  flex: 1;
}

.xinxi div img {
  float: left;
  margin-top: 3px;
}

.xinxi div p {
  float: left;
  line-height: 0px;
  color: #ffffff;
}

.tablist {
  width: 1090px;
  margin: 50px auto;
}

.content>div{
  height: 600px;
  box-sizing: border-box;
  padding-left: 20px;
  overflow-y: scroll;
}
</style>
