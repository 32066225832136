<template>
  <div class="concent2">
    <div class="pingtai">
      <img class="head-bg" src="../assets/image/155.png" alt="">
      <div class="caidan">
        <a href="http://admin.linyiwenlv.cn/">
          <div>溯源管理</div>
        </a>
        <!-- <a href="http://39.153.185.9:8081">
          <div>农牧业大数据展示</div>
        </a> -->
        <a href="http://www.sdcdc.cn/">
          <div>防疫监管</div>
        </a>
        <a href="http://www.linyixian.gov.cn/n54935142/n54935152/n67601531/n67604384/n67605335/c67620274/content.html">
          <div>机构队伍</div>
        </a>
        <!-- <a href="http://www.dmlhq.gov.cn/">
          <div>投入品管理</div>
        </a> -->
      </div>
    </div>
    <div class="list">
      <el-menu class="el-menu-demo" mode="horizontal" background-color="#019966" text-color="#fff"
        active-text-color="#FFF">
        <!-- <el-menu-item class="elmenu" @click="shouye" index="1">首页</el-menu-item> -->
        <el-menu-item v-for="(item, index) in router" :key="index" :class="[item.path == selRouter ? 'elmenu' : '']"
          @click="nongye(item.path)" index="index">{{ item.name }}</el-menu-item>
        <!-- <el-menu-item class="elmenu" @click="damaoqi" index="2">特色达茂旗</el-menu-item> -->
        <!-- <el-menu-item class="elmenu" @click="xinwen" index="3">新闻资讯</el-menu-item> -->
        <!-- <el-menu-item class="elmenu" @click="qiye" index="5">监管机构</el-menu-item> -->
        <!-- <el-menu-item class="elmenu" @click="gongxu" index="8">企业供需</el-menu-item> -->
        <!-- <el-menu-item class="elmenu" @click="ruzhu" index="6">入驻企业</el-menu-item> -->
        <!-- <el-menu-item class="elmenu" @click="jianyi" index="7">咨询建议</el-menu-item> -->
        <!-- <el-menu-item class="elmenu" @click="guanyu" index="4">关于我们</el-menu-item> -->
      </el-menu>
    </div>
  </div>
</template>
<script>
export default {
  name: "Caidna",
  data() {
    return {
      router: [
        {
          path: '/',
          name: '首页',
        },
        {
          path: '/diqu',
          name: '特色地区',
        },
        {
          path: '/zixun',
          name: '最新资讯',
        },
        {
          path: '/zhengce',
          name: '国家政策',
        },
        {
          path: '/zhishi',
          name: '农技知识',
        },
        {
          path: '/qiye',
          name: '名优企业',
        },
        {
          path: '/jigou',
          name: '监管机构',
        },
        {
          path: '/jianyi',
          name: '咨询建议',
        },
      ],
      selRouter: '/'
    }
  },
  watch: {
    $route: {
      handler(newRoute, oldRoute) {
        console.log('newRoute', newRoute);
        console.log('oldRoute', oldRoute);
        this.selRouter = newRoute.fullPath
      },
      immediate: true,
    },
  },
  methods: {
    shouye: function () {
      this.$router.push({ path: "/" })
    },
    damaoqi: function () {
      this.$router.push({ path: '/damaoqi' })
    },
    xinwen: function () {
      this.$router.push({ path: "/xinwen" })
    },
    guanyu: function () {
      this.$router.push({ path: "/guanyu" })
    },
    qiye: function () {
      this.$router.push({ path: "/qiye" })
    },
    jianyi: function () {
      this.$router.push({ path: "/jianyi" })
    },
    ruzhu: function () {
      this.$router.push({ path: "/ruzhu" })
    },
    gongxu: function () {
      this.$router.push({ path: "/gongxu" })
    },

    nongye(path) {
      this.selRouter = path
      this.$router.push({ path: path })
    }

  }
}
</script>

<style>
.concent2{
  margin-bottom: 20px;
}
a {
  text-decoration: none;
}
.head-bg{
  width: 100%;
}
.pingtai {
  position: relative;
}

.herder {
  font-size: 30px;
  color: #71ae47;
  position: absolute;
  top: 6px;
  left: 190px;
}

.el-menu-item,
.el-menu {
  background-color: transparent !important;
  border: none !important;
}

.el-menu-item.is-active {
  border: none !important;
}

.el-menu-item:hover {
  background-color: #f6aa26 !important;
}

.herder1 {
  width: 350px;
  height: 40px;
  position: absolute;
  top: 55px;
  left: 190px;
}

.caidan {
  position: absolute;
  bottom: 10px;
  right: 110px;
  z-index: 1;
  font-weight: 800;
}

.caidan div {
  display: inline;
  padding-left: 30px;
  color: #009966;
}

.el-menu-demo {
  display: flex;
  justify-content: center;
  text-align: center;
}

.elmenu {
  background-color: #f6aa26 !important;
}

.list {
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  padding: 0 110px;
  /* background-color: #019966; */
  background-image: url("../assets/newImg/navbg.png");

}

.list ul {
  width: 100%;
  height: 100%;
}

.list ul li {
  width: 14%;
  height: 100%;
  font-size: 20px;
}</style>