<template>
  <div class="beijing">
    <div class="xinxi">
      <div>
        <img style="width: 15px;height: 19px;" src="../assets/logo/1.png" alt="">
        <p style="font-size:12px;margin-left: 10px;">地址:山东省德州市临邑县临南镇</p>
      </div>
      <div>
        <img style="width: 19px;height: 19px;" src="../assets/logo/2.png" alt="">
        <p style="font-size:12px;margin-left: 10px;">电话:0534-4710111</p>
      </div>
      <div>
        <img style="width: 20px;height: 16px;" src="../assets/logo/3.png" alt="">
        <p style="font-size:12px;margin-left: 10px;">邮编:251500</p>
      </div>
      <div>
        <img style="width: 18px;height: 15px;" src="../assets/logo/4.png" alt="">
        <p style="font-size:12px;margin-left: 10px;">邮箱:lnzrmzf@dz.shandong.cn</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {
    }
  },
}
</script>

<style>
.beijing {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  background-color: #52bb9d;
  background-size: 100% 100%;
}

.xinxi div {
  flex: 1;
}

.xinxi div img {
  float: left;
  margin-top: 3px;
}

.xinxi div p {
  float: left;
  line-height: 0px;
  color: #ffffff;
}
</style>
