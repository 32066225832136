<template>
  <div class="concent">
    <Caidan></Caidan>
    <div class="banner">
      <img class="logo"
           src="https://s1.ax1x.com/2020/06/15/NCmWuR.png"
           alt="">
    </div>
    <div class="wenzhang"
         style="border:none">
      <div class="biaoti">{{info.name}}</div>
      <img class="wenzhang_img"
           :src="IMAGE_BASE_URL+info.img" />
      <div class="wenzhang_val">{{info.tongzhixinxi}}</div>
    </div>
    <div class="beijing"
         style="width:100%">
      <div class="xinxi">
        <div>
          <img src="../assets/logo/1.png"
               alt="">
          <p style="font-size:12px;margin-left: 10px;">地址:山东省德州市临邑县临南镇</p>
        </div>
        <div>
          <img src="../assets/logo/2.png"
               alt="">
          <p style="font-size:12px;margin-left: 10px;">电话:0534-4710111</p>
        </div>
        <div>
          <img src="../assets/logo/3.png"
               alt="">
          <p style="font-size:12px;margin-left: 10px;">邮编:251500</p>
        </div>
        <div>
          <img src="../assets/logo/4.png"
               alt="">
          <p style="font-size:12px;margin-left: 10px;">邮箱:lnzrmzf@dz.shandong.cn</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      info: []
    }
  },
  mounted: function () {
    // this.zhengce()
    this.getshuju()
    // this.getshuju1()
    // this.getshuju2()
  },
  methods: {
    getshuju () {
      var that = this
      //   console.log(that.$route.query.id)
      //   var url = `http://192.168.0.101:8089/dmq/gjzc/jilu/${that.$route.query.id}`
      //   console.log(url)
      if (that.$route.query.id) {
        this.$http.get('platform/gjzc/details/' + that.$route.query.id)
          .then(function (response) {
            console.log(response);
            // this.data.info=response.data.pd
            that.info = response.data.data
          })
          .catch(function (error) {
            console.log(error, "出错");
          });
      } else if (that.$route.query.id1) {
        this.$http.get('platform/nszx/details/' + that.$route.query.id1)
          .then(function (response) {
            console.log(response);
            // this.data.info=response.data.pd
            that.info = response.data.data
          })
          .catch(function (error) {
            console.log(error, "出错");
          });
      } else {
        this.$http.get('platform/tzgg/details/' + that.$route.query.id2)
          .then(function (response) {
            console.log(response);
            // this.data.info=response.data.pd
            that.info = response.data.data
          })
          .catch(function (error) {
            console.log(error, "出错");
          });
      }

    }
  }
}
</script>

<style>
.concent {
  background-color: #f9f9f9;
}
.banner .logo {
  width: 100%;
  height: 100%;
}
.wenzhang {
  width: 800px;
  height: 800px;
  background-color: #ffffff;
  margin: 0 auto;
}
.wenzhang .wenzhang_img {
  width: 500px;
  height: 300px;
  margin: 0 150px;
}
.biaoti {
  width: 500px;
  height: 50px;
  font-size: 20px;
  text-align: center;
  line-height: 50px;
  margin: 0 150px;
}
.wenzhang_val {
  width: 700px;
  margin: 20px 50px !important;
  text-align: left;
  text-indent: 2em;
  padding-bottom: 10px;
}
.wenzhang .wenzhang_img1 {
  margin: 0 200px;
}

.beijing {
  width: 100%;
  height: 50px;
  /* background-image: url("../assets/image/165.png"); */
  background-color: #52bb9d;

  background-size: 100% 100%;
  display: flex;
  align-items: center;
}
.beijing .xinxi {
  width: 1000px;
  height: 25px;
  margin: 0 auto;
  display: flex;
}
.xinxi div {
  flex: 1;
}
.xinxi div img {
  float: left;
  margin-top: 3px;
}
.xinxi div p {
  float: left;
  line-height: 0px;
  color: #ffffff;
}
.banquan {
  font-size: 12px;
  color: #ffffff;
  width: 120px;
  height: 20px;
  margin: -10px auto;
}
</style>
