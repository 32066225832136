<template>
  <div class="block">
    <Caidan v-if="column==3"></Caidan>
    <el-timeline class="timeLine"
                 v-for="(item,index) in zhuisu"
                 :key="index"
                 :style="column==3?'margin:100px':'padding-left:10px;margin-top:50px'">
      <el-timeline-item :timestamp="item.type | typeFilter(item.productInfo.createTime)"
                        placement="top">
        <el-card>
          <el-descriptions class="margin-top"
                           :column="column"
                           size="medium"
                           border>
            <el-descriptions-item>
              <template slot="label">
                生产档案编码
              </template>
              {{item.danganInfo.scdabm}}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                企业名称
              </template>
              {{item.userInfo.nickName}}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                档案批次号
              </template>
              {{item.danganInfo.dapch}}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                企业法人
              </template>
              {{item.userInfo.legalPerson}}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                种类
              </template>
              {{item.danganInfo.zhonglei}}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                企业手机号码
              </template>
              {{item.userInfo.phonenumber}}

            </el-descriptions-item>
            <el-descriptions-item v-if="item.danganInfo.pinzhong">
              <template slot="label">
                品种
              </template>
              {{item.danganInfo.pinzhong}}
            </el-descriptions-item>
            <el-descriptions-item v-if="item.danganInfo.pingzhong">
              <template slot="label">
                品种
              </template>
              {{item.danganInfo.pingzhong}}
            </el-descriptions-item>
            <el-descriptions-item v-if="item.danganInfo.jidi">
              <template slot="label">
                基地
              </template>
              {{item.danganInfo.jidi}}
            </el-descriptions-item>
            <el-descriptions-item v-if="item.danganInfo.slmj">
              <template slot="label">
                基地种植面积（亩）
              </template>
              {{item.danganInfo.slmj}}
            </el-descriptions-item>
            <el-descriptions-item v-if="item.danganInfo.shuliang">
              <template slot="label">
                基地养殖数量
              </template>
              {{item.danganInfo.shuliang}}
            </el-descriptions-item>
            <el-descriptions-item v-if="item.type=='jiagong'">
              <template slot="label">
                原料数量
              </template>
              {{item.productInfo.productNum}}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                收获产品
              </template>
              {{item.productInfo.name}}
            </el-descriptions-item>
            <el-descriptions-item v-if="item.type=='yangzhi_tuzai' ">
              <template slot="label">
                屠宰公司
              </template>
              {{item.productInfo.companyName}}
            </el-descriptions-item>
            <el-descriptions-item v-if="item.type=='yangzhi_tuzai' ">
              <template slot="label">
                屠宰公司地址
              </template>
              {{item.productInfo.address}}
            </el-descriptions-item>
            <el-descriptions-item v-if="item.type=='yangzhi_tuzai' ">
              <template slot="label">
                屠宰公司联系方式
              </template>
              {{item.productInfo.phonenumber}}
            </el-descriptions-item>
            <el-descriptions-item v-if="item.type=='tuzai'||item.type=='yangzhi_tuzai' ">
              <template slot="label">
                屠宰数量
              </template>
              {{item.productInfo.productNum}}
            </el-descriptions-item>
            <el-descriptions-item v-if="item.type=='tuzai'||item.type=='yangzhi_tuzai' ">
              <template slot="label">
                屠宰产品
              </template>
              {{item.productInfo.name}}
            </el-descriptions-item>
            <!-- <el-descriptions-item v-if="item.type=='jiagong'">
              <template slot="label">
                收获数量
              </template>
              {{item.productInfo.harvestNum}}
            </el-descriptions-item> -->
            <el-descriptions-item v-if="item.productInfo.image">
              <template slot="label">
                生产图片
              </template>
              <el-image style="width: 100px; height: 100px"
                        :src="baseUrl+item.productInfo.image">
              </el-image>
            </el-descriptions-item>
            <el-descriptions-item v-if="item.productInfo.credential">
              <template slot="label">
                动物检疫合格证
              </template>
              <el-image style="width: 100px; height: 100px"
                        :src="item.productInfo.credential | imageFilter(baseUrl)">
              </el-image>
            </el-descriptions-item>
            <el-descriptions-item v-if="item.productInfo.credential">
              <template slot="label">
                肉品品质检验合格证
              </template>
              <el-image style="width: 100px; height: 100px"
                        :src="item.productInfo.credential | image1Filter(baseUrl)">
              </el-image>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                营业执照
              </template>
              <el-image style="width: 100px; height: 100px"
                        :src="baseUrl+item.userInfo.businessLicense">
              </el-image>
            </el-descriptions-item>
            <el-descriptions-item v-if="item.danganInfo.sellUserName">
              <template slot="label">
                供应商名称
              </template>
              {{item.danganInfo.sellUserName}}
            </el-descriptions-item>
            <el-descriptions-item v-if="item.danganInfo.sellPhone">
              <template slot="label">
                供应商联系方式
              </template>
              {{item.danganInfo.sellPhone}}
            </el-descriptions-item>
            <el-descriptions-item v-if="item.danganInfo.sellAddress">
              <template slot="label">
                供应商地址
              </template>
              {{item.danganInfo.sellAddress}}
            </el-descriptions-item>
            <el-descriptions-item v-if="item.danganInfo.sellIdcard">
              <template slot="label">
                供应商执照/身份证
              </template>
              <el-image style="width: 100px; height: 100px"
                        :src="baseUrl+item.danganInfo.sellIdcard">
              </el-image>
            </el-descriptions-item>
          </el-descriptions>
          <div class="table"
               v-if="item.jiluInfo">
            <div class="title">生产记录:</div>
            <el-table :data="item.jiluInfo"
                      border
                      max-height="250"
                      style="width: 100%">
              <el-table-column prop="type"
                               label="生产类型">
              </el-table-column>
              <el-table-column prop="name"
                               label="投入品名称"
                               width="100">
              </el-table-column>
              <el-table-column prop="yongliang"
                               label="用量">
              </el-table-column>
              <el-table-column prop="renyuan"
                               label="操作人">
              </el-table-column>
              <el-table-column prop="date"
                               label="日期"
                               width="180">
              </el-table-column>

            </el-table>
          </div>
        </el-card>
      </el-timeline-item>
      <el-timeline-item :timestamp="'销售'"
                        placement="top"
                        v-if="item.marketInfo && item.marketInfo.length > 0">
        <el-card>
          <div class="table">
            <div class="title">销售记录:</div>
            <el-table :data="item.marketInfo"
                      border
                      max-height="250"
                      style="width: 100%">
              <el-table-column label="个人(企业)名称">
                <template slot-scope="scope">
                  {{scope.row.companyName?scope.row.companyName:scope.row.legalPerson}}
                </template>
              </el-table-column>
              <el-table-column prop="legalPerson"
                               label="姓名(法人)">
              </el-table-column>
              <el-table-column prop="phonenumber"
                               label="联系方式">
              </el-table-column>
              <el-table-column prop="productNum"
                               label="购买数量">
              </el-table-column>
              <el-table-column prop="address"
                               label="买方地址">
              </el-table-column>
              <el-table-column prop="createTime"
                               label="销售日期"
                               width="180">
              </el-table-column>

            </el-table>
          </div>
        </el-card>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>
<script>
export default {
  data () {
    return {
      baseUrl: "",
      column: "",
      zhuisu: []
    }
  },
  watch: {
    column (val) {
      console.log(val)
    }
  },
  filters: {
    typeFilter (value, createTime) {
      if (value == "zhongzhi") {
        return '种植 ' + createTime
      } else if (value == "yangzhi") {
        return '养殖 ' + createTime
      } else if (value == "yangzhi_tuzai") {
        return '养殖屠宰 ' + createTime
      } else if (value == "tuzai") {
        return '屠宰 ' + createTime
      } else if (value == "jiagong") {
        return '加工 ' + createTime
      }
    },
    imageFilter (value, baseUrl) {
      if (value) {
        return baseUrl + value.split(',')[0]
      }
    },
    image1Filter (value, baseUrl) {
      if (value) {
        console.log(value, 21234)
        return baseUrl + value.split(',')[1]
      }
    }
  },
  mounted () {
    // window.onresize = () => {
    // };
    if (this._isMobile()) {
      this.getshuju()
      return this.column = 1
    } else {
      this.getshuju()
      return this.column = 3
    }
  },
  methods: {
    getshuju () {
      var that = this
      var input = that.$route.query.a
      that.baseUrl = that.IMAGE_BASE_URL
      this.$http.get('/platform/zhuisu/' + input).then(function (response) {
        if (response.data.code == 5001) {
          that.$message({ message: "没有查到该溯源码对应的产品", type: 'error' });
        } else {
          that.zhuisu = response.data.data.reverse()
        }
      })
    },
    _isMobile () {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag;
    }
  }
}
</script>

<style>
.timeLine {
  /* margin-top: 100px; */
}

.timeLine .table {
  margin-top: 20px;
}

.timeLine .table .title {
  margin: 20px 0;
  font-size: 16px;
  color: #555555;
}
</style>