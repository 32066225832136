<template>
  <div class="concent">
    <!-- <Caidan></Caidan> -->
    <div class="banner">
      <img class="logo"
           src="https://s1.ax1x.com/2020/06/15/NCmWuR.png">
    </div>
    <div class="wenzhang">
      <div class="biaoti">企业名称:{{userInfo.nickName}}</div>
      <div class="wenzhang_val">
        <p style="font-size: 20px;text-align: center;">企业介绍</p>
        <div class="itemList">
          {{userInfo.details}}
        </div>
      </div>
      <div class="wenzhang_val">
        <p style="font-size: 20px;text-align: center;">企业资质</p>
        <div class="itemList">
          <el-image class="item"
                    :src="IMAGE_BASE_URL+item"
                    v-for="(item,index) in userInfo.qualificationImg"
                    :key='index'
                    @click="lookImg(item)"></el-image>
        </div>
      </div>
      <div class="wenzhang_val">
        <p style="font-size: 20px;text-align: center;">企业证书</p>
        <div class="itemList">
          <el-image class="item"
                    :src="IMAGE_BASE_URL+item"
                    v-for="(item,index) in userInfo.honorImg"
                    :key='index'
                    @click="lookImg(item)"></el-image>
        </div>
      </div>
      <div class="wenzhang_val">
        <p style="font-size: 20px;text-align: center;">企业产品</p>
        <el-table ref="filterTable"
                  :data="tableData"
                  style="width: 100%">
          <el-table-column prop="tag"
                           label="产品图片"
                           width="100"
                           align="center">
            <template slot-scope="scope">
              <el-image :src="IMAGE_BASE_URL+scope.row.img"
                        style="width:100px;height:100px"
                        @click="lookImg(scope.row.img)"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="productName"
                           label="产品名称"
                           width="180"
                           align="center">
          </el-table-column>
          <el-table-column prop="craft"
                           label="产品工艺"
                           width="180"
                           align="center">
          </el-table-column>
          <el-table-column prop="sanpinyibiao"
                           label="三品一标"
                           align="center">
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible"
               title="预览"
               width="800"
               append-to-body>
      <img :src="dialogImageUrl"
           style="display: block; max-width: 100%; margin: 0 auto" />
    </el-dialog>
    <div class="beijing"
         style="width:100%">
      <div class="xinxi">
        <div>
          <img src="../assets/logo/1.png">
          <p style="font-size:12px;margin-left: 10px;">地址:山东省德州市临邑县临南镇</p>
        </div>
        <div>
          <img src="../assets/logo/2.png">
          <p style="font-size:12px;margin-left: 10px;">电话:0534-4710111</p>
        </div>
        <div>
          <img src="../assets/logo/3.png">
          <p style="font-size:12px;margin-left: 10px;">邮编:251500</p>
        </div>
        <div>
          <img src="../assets/logo/4.png">
          <p style="font-size:12px;margin-left: 10px;">邮箱:lnzrmzf@dz.shandong.cn</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      dialogVisible: false,
      userInfo: [],
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄',
        tag: '家'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1517 弄',
        tag: '公司'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1519 弄',
        tag: '家'
      }, {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄',
        tag: '公司'
      }]
    }
  },
  mounted: function () {
    this.getshuju()
  },

  methods: {
    lookImg (img) {
      this.dialogVisible = true
      this.dialogImageUrl = this.IMAGE_BASE_URL + img
    },
    getshuju () {
      var that = this
      if (that.$route.query.id) {
        this.$http.get('dmq/product/getCompanyDetails/' + that.$route.query.id
        ).then(function (response) {
          that.userInfo = response.data.data.user
          if (response.data.data.user.qualificationImg) {
            that.userInfo.qualificationImg = response.data.data.user.qualificationImg.split(',')
          }
          if (response.data.data.user.honorImg) {
            that.userInfo.honorImg = response.data.data.user.honorImg.split(',')
          }
          that.tableData = response.data.data.list
        })
          .catch(function (error) {
            console.log(error, "出错");
          });
      }
    }
  }
}
</script>

<style>
.concent {
  background-color: #f9f9f9;
}
.banner .logo {
  width: 100%;
  height: 100%;
}
.wenzhang {
  width: 800px;
  height: 100%;
  background-color: #ffffff;
  /* border: 1px solid #333; */
  margin: 0 auto;
}
.wenzhang .wenzhang_img {
  width: 400px;
  height: 140px;
  margin: 0 150px;
}
.biaoti {
  width: 500px;
  height: 50px;
  font-size: 20px;
  text-align: center;
  text-indent: 2em;
  padding-bottom: 10px;
  line-height: 50px;
  margin: 0 150px;
}
.wenzhang_val {
  width: 700px;
  margin: 0 50px;
  text-align: left;
  text-indent: 2em;
  padding-bottom: 10px;
  font-size: 14px;
}
.wenzhang_val .itemList {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.wenzhang_val .itemList .item {
  width: 24%;
  height: 200px;
  margin-top: 20px;
}
.wenzhang .wenzhang_img1 {
  margin: 0 150px;
}
.beijing {
  height: 50px;
  display: flex;
  align-items: center;
  /* background-image: url("../assets/image/165.png"); */
  background-color: #52bb9d;

  background-size: 100% 100%;
  position: relative;
  /* top: 20px; */
}
.xinxi div {
  flex: 1;
}
.xinxi div img {
  float: left;
  margin-top: 3px;
}
.xinxi div p {
  float: left;
  line-height: 0px;
  color: #ffffff;
}
</style>
