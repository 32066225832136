<template>
  <div class="toubu">
    <!-- <Caidan></Caidan> -->
    <!-- 轮播 -->
    <el-carousel trigger="click" height="550px">
      <el-carousel-item v-for="item in swiperImgs" :key="item.pic_url">
        <img :src="item.pic_url" style="width: 100%; height: 100%;">
      </el-carousel-item>
    </el-carousel>


    <div class="pingtai1">
      <!-- <div class="banner">
        <img src="../assets/image/162-1.png" />
      </div> -->
      <!-- <div class="sousuo">
        <el-input class="sousuo1" maxlength=21 :onkeyup="input = input.replace(/[^\d]/g, '')" v-model="input"
          placeholder="请输入溯源码" :disabled="false">
        </el-input>
        <el-button @click="suyuan" plain icon="el-icon-search"
          style="position: absolute;top: 50px;left: 400px; background-color:#ff9900;color:#fff"
          type="primary">溯源</el-button>
      </div> -->

      <!-- 右侧悬浮 -->
      <div class="logo">
        <a href="http://nyncj.dezhou.gov.cn/">
          <img style="width:60px;height:60px" src="../assets/image/159.png" alt="">
        </a>
        <img @click="tousu" src="../assets/image/160.png" alt="">
        <!--        <img @click="shexiang" src="../assets/image/161.png" alt="">-->
        <img @click="jianyi" src="../assets/image/157.png">
        <a href="http://forecast.weather.com.cn/town/weather1dn/101120403005.shtml#input">
          <img style="width:60px;height:60px" src="../assets/image/158.png">
        </a>
      </div>

      <!-- 左侧悬浮 -->
      <div class="logo1">
        <img style="width:200px" src="../assets/image/zu10.png" alt="">
        <img style="width:100px;margin-top: 20px;" src="../assets/ewm.jpg" alt="">
      </div>


      <div class="tousu" v-if="xianshi">
        <button @click="guanbi" class="guanbi">X</button>
        <div>{{ dianhua }}{{ phone }}</div>
      </div>

      <div class="videoArea" style="z-index:9999" v-if="xianshi1">
        <!-- <button @click="guanbi1" class="guanbi1">关闭</button> -->
        <h2>视频图像区</h2>
        <input type="button" title="开启摄像头" value="开启摄像头" @click="getMedia" />
        <a href="http://36.153.185.9:8090">
          <input type="button" title="关闭摄像头" value="退出" />
        </a>
        <video id="myVideo" autoplay="autoplay"></video>
      </div>

      <!-- <div class="lunbo">
                    <el-carousel style="margin:0 auto" :interval="4000" type="card" height="200px">
                            <el-carousel-item v-for="item in imagesbox" :key="item.id">
                                <img :src="item.idView" class="image">
                            </el-carousel-item>
                    </el-carousel>
            </div> -->

      <!-- <div> -->
      <!-- <transition name="fade">
          <div @touchend='end(event)' @touchstart='start' @touchmove='move' class="swiper">
            <div @click="chooseItem(item, index)" v-for="(item, index) in swiperImgs" :style="config5[index]" :key="index">
              <img :src="item.pic_url" style="width: 100%; height: 100%;">
            </div>
          </div>
        </transition> -->
      <!-- <h1 @click="prev" class="left">上一个</h1>
                <h1 @click="next" class="right">下一个</h1> -->
      <!-- </div> -->

    </div>

    <!-- <div class="fugai">
      <div class="kongbai"></div>
      <div class="gonggao">
        <img src="../assets/image/gonggao.png">
        <div style="color:#fff;margin-left:20px">
          <vue-seamless-scroll :data="fourData" :class-option="classOption" class="warp">
            <div class="gonggaoList">
              <div v-for="(item) in gonggaoList" :key="item.noticeId">
                <p v-if="item.noticeType == 2"> 网站公告:{{ item.noticeTitle }}</p>
                <p v-if="item.noticeType == 1"> 网站通知:{{ item.noticeTitle }}</p>
              </div>
            </div>
          </vue-seamless-scroll>
        </div>
      </div> -->
    <!-- <div class="value-bg"> -->
    <!-- <div>
        <img src="../assets/image/165-1.png" style="width:1090px;height:17px" alt="花边">
      </div> -->
    <!-- <div class="xinwen">
        <div class="xinwen-logo">
          <img class="xinwen-img" src="../assets/image/100.png" alt="">
          <img style="float:right;margin-top: 10px;width: 90px;margin-right: 10px;" src="../assets/image/168.png" alt="">
        </div>
        <div class="zixun">
          <div class="lunbo3">
            <el-carousel trigger="click" height="250px">
              <el-carousel-item v-for="(item, index) in imagesBox" :key="index">
                <img :src="item.idView">
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="tab">
            <el-tabs class="tab1" style="background-color:#ffffff" type="border-card">
              <el-tab-pane label="国家政策">
                <div v-for="(item, index) in info" :key="index">
                  <router-link :to="{ path: '/XinwenVal', query: { id: item.id } }">
                    <div class="zixun1">
                      <div style="flex:1;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">{{ item.name }}
                      </div>
                      <div style="margin-left:10px;width:100px">{{ item.tianjiashijian }}</div>
                    </div>
                  </router-link>
                </div>

              </el-tab-pane>
              <el-tab-pane label="农事咨询">
                <div v-for="(item, index) in info1" :key="index + 1">
                  <router-link :to="{ path: '/XinwenVal', query: { id1: item.id } }">
                    <div class="zixun1">
                      <div style="flex:1;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">{{ item.name }}
                      </div>
                      <div style="margin-left:10px;width:100px">{{ item.tianjiashijian }}</div>
                    </div>
                  </router-link>
                </div>

              </el-tab-pane>
              <el-tab-pane label="通知公告">
                <div v-for="(item, index) in info2" :key="index + 2">
                  <router-link :to="{ path: '/XinwenVal', query: { id2: item.id } }">
                    <div class="zixun1" :id="item.id">
                      <div style="flex:1;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">{{ item.name }}
                      </div>
                      <div style="margin-left:10px;width:100px">{{ item.tianjiashijian }}</div>
                    </div>
                  </router-link>
                </div>

              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div> -->

    <!-- <div class="jianjie">
        <div class="pingtai_jianjie">
          <div class="pingtai_jianjie1">
            <img style="width:150px;margin-left:10px" src="../assets/image/101.png" alt="">
          </div>
          <div class="wenzi">
            <p>
              达茂旗农畜产品(食品)质量安全追溯服务平台为落实国务院加快应用现代信息技术建设重要产品追溯体系的重大部署物联网技术，率先在农牧行业建立全国统一质量追溯平台。消费者可以通过平台进行追溯查询、享受在线农技服务及查询优质农资商品；农资流通企业可利用平台提供的数据共享与集成机制，实现上、下游产业链环节的数据获取，从而为企业的经营生产提供决策支撑。政府监管部门可依托平台对农资流通进行监管，从而保障农牧市场安全。
            </p>
          </div>
          <img @click="guanyu" class="jianjie1" src="../assets/image/24.png" alt="">
        </div>
        <div class="chaxun">
          <div class="chaxun-img">
            <img style="width:170px;margin-left:10px" src="../assets/image/102.png" alt="">
          </div>

          <div class="chaxun1">
            <el-input class="SuYuanMa" style="width:220px" :onkeyup="value = value.replace(/[^\d]/g, '')"
              placeholder="请输入溯源码" v-model="value" :disabled="false">
            </el-input>
            <el-button class="dian_but" :plain="true" @click="suyuan1" type="primary"
              style="background-color:#ff9900;color:#fff">溯源</el-button>
          </div>
        </div>
        <div class="ruzhu">
          <div class="ruzhu-img">
            <img style="width:160px;margin-left:10px;margin-top:10px" src="../assets/image/166.png" alt="">
          </div>
          <div class="ruzhu-value" style="overflow: hidden;">
            <vue-seamless-scroll :data="fourData" :class-option="classOption" class="warp">
              <ul id="scrollList">
                <li id="li1" v-for="(item) in fourData" :key="item.userId">
                  <span>{{ item.nickName }}</span>
                </li>
              </ul>
            </vue-seamless-scroll>
          </div>
        </div>

      </div> -->

    <!-- <div class="fangwei">
        <div class="fangwei2">
          <img src="../assets/image/103.png" alt="">
          <p class="fangwei3">一品一码</p>
          <p class="fangwei1">每一个产品都有一个唯一的溯源码识别，不可仿照，不可替代。</p>
        </div>
        <div class="fangwei2">
          <img src="../assets/image/104.png" alt="">
          <p class="fangwei3">全程跟踪</p>
          <p class="fangwei1">从生产检测加工到销售，全程位置信息跟踪</p>
        </div>
        <div class="fangwei2">
          <img src="../assets/image/105.png" alt="">
          <p class="fangwei3">防伪技术</p>
          <p class="fangwei1">一品一码、全程跟踪、位置信息</p>
        </div>
      </div> -->

    <!-- <div class="lunbo4">
      <el-carousel height="250px" trigger="click">
        <el-carousel-item v-for="item in images" :key="item.id">
          <img class="lunbo-img" :src="item.img" style="width: 100%; height: 100%;" alt="">
        </el-carousel-item>
      </el-carousel>
    </div> -->
    <!-- </div> -->

    <!-- 特色地区 -->
    <div class="diqu" style="margin-top: 30px;">
      <router-link :to="{ path: '/diqu' }">
        <div class="title">
          <div class="title_more">特色地区</div>
          <span class="more">更多</span>
        </div>
      </router-link>
      <div class="diqulist">
        <div class="item" v-for="(item, index) in tsdqList" :key="index" v-if="index < 5"
          @click="toTese(item.article_id)">
          <div class="img">
            <img v-if="item.img" :src="item.img" alt="">
            <img v-else src="../assets/暂无图片.png" alt="">
          </div>
          <div class="div">{{ item.article_name }}</div>
        </div>
      </div>
    </div>

    <!-- 监管机构 -->
    <div class="jigou">
      <router-link :to="{ path: '/jigou' }">
        <div class="title">
          <div class="title_more">监管机构</div>
          <span class="more">更多</span>
        </div>
      </router-link>
      <div class="jigoulist">
        <div id="tu0" class="tu0">
          <img class="img" src="../assets/jianguan/1.png" alt="">
        </div>
        <div class="tu1">
          <img class="img" src="../assets/jianguan/2.png" alt="">
        </div>
        <div class="tu2">
          <img class="img" src="../assets/jianguan/3.png" alt="">
        </div>
        <div class="tu3">
          <img class="img" src="../assets/jianguan/4.png" alt="">
        </div>
        <div class="tu4">
          <img class="img" src="../assets/jianguan/5.png" alt="">
        </div>
        <div class="tu5">
          <img class="img" src="../assets/jianguan/6.png" alt="">
        </div>
        <div class="tu6">
          <img class="img" src="../assets/jianguan/7.png" alt="">
        </div>
        <div class="tu7">
          <img class="img" src="../assets/jianguan/8.png" alt="">
        </div>
        <div class="tu8">
          <img class="img" src="../assets/jianguan/9.png" alt="">
        </div>
        <div class="tu9">
          <img class="img" src="../assets/jianguan/10.png" alt="">
        </div>
        <div class="tu10">
          <img class="img" src="../assets/jianguan/11.png" alt="">
        </div>
      </div>
    </div>




    <!-- 关于我们 -->
    <div class="guanyu-box">
      <div class="guanyu">
        <!-- <div class="title">关于我们</div>
        <div class="tips">...</div>
        <router-link :to="{ path: '/guanyu' }">
          <div class="tips2">+</div>
        </router-link> -->
        <div class="bott">
          <div class="gu_01"></div>
          <div class="gu_02"></div>
          <div class="gu_03"></div>
          <!-- <img src="../assets/001.png" alt=""> -->
          <!-- <img src="../assets/022.png" alt=""> -->
          <!-- <img src="../assets/003.png" alt=""> -->
        </div>
      </div>
    </div>


    <div class="main">
      <!-- <router-link :to="{ path: '/zixun' }"> -->

      <div class="block">
        <router-link :to="{ path: '/zixun' }">
          <div class="title">
            <div class="title_more">最新资讯</div>
            <span class="more">更多</span>
          </div>
        </router-link>
        <div class="content">
          <div class="line-one">
            <div class="img">
              <img v-if="oneXW.img" :src="oneXW.img" alt="">
              <img v-else src="../assets/暂无图片.png" alt="">
            </div>
            <div class="one-right">
              <div class="titler">{{ oneXW.article_name }}</div>

              <div class="cont">{{ oneXW.article_name }}</div>
              <div class="btn" @click="toZixun(oneXW.article_id)">查看详细</div>
            </div>
          </div>
          <div class="line" v-for="(item, index) in xwzxList" :key="index" v-if="index > 0"
            @click="toZixun(item.article_id)">
            <span class="xian">-</span>
            <span class="titler">{{ item.article_name }}</span>
            <span class="timer">{{ item.time }}</span>
          </div>
        </div>
      </div>
      <!-- </router-link> -->
      <!-- <router-link :to="{ path: '/zixun' }"> -->

      <div class="block">
        <router-link :to="{ path: '/zhishi' }">
          <div class="title">
            <div class="title_more">农技知识</div>
            <span class="more">更多</span>
          </div>
        </router-link>
        <div class="content">
          <div class="line-one">
            <div class="img">
              <img v-if="oneNj.img" :src="oneNj.img" alt="">
              <img v-else src="../assets/暂无图片.png" alt="">
            </div>

            <div class="one-right">
              <div class="titler">{{ oneNj.article_name }}</div>
              <!-- <div class="cont" v-html="oneNj.content"></div> -->
              <div class="cont">{{ oneNj.article_name }}</div>

              <div class="btn" @click="toNoji(oneXW.article_id)">查看详细</div>
            </div>
          </div>
          <div class="line" v-for="(item, index) in njzsList" :key="index" v-if="index > 0"
            @click="toNoji(item.article_id)">
            <span class="xian">-</span>
            <span class="titler">{{ item.article_name }}</span>
            <span class="timer">{{ item.time }}</span>
          </div>
        </div>
      </div>
      <!-- </router-link> -->

    </div>

    <!-- 合作机构 -->
    <div class="hezuo">
      <div class="hezuo-cont">
        <h5 class="hezuo-left flex">合作机构</h5>
        <a class="flex" href="http://www.greenfood.agri.cn/"><img style="height: 20px;" src="../assets/image/125.png"></a>
        <!-- <a class="flex" href="http://www.ietrace.com/"><img style="height: 34px;" src="../assets/image/122.png"></a>
        <a class="flex" href="http://nmg.foodqs.org/FuboPlat/Website/Interactive/DefaultInteractive.aspx"><img
            style="height: 60px;" src="../assets/image/124.png"></a> -->
        <a class="flex" href="http://www.moa.gov.cn/">中华人民共和国农业农村部 </a>
        <a class="flex" href="http://www.sdzjy.com.cn/">山东省产品质量检验研究院 </a>

        <a class="flex" href="https://www.shiansd.net/"><img style="height: 46px;" src="../assets/image/11223.png"></a>
      </div>

    </div>

    <!-- 特色产品 -->
    <!-- <div class="tese">
        <div class="tese-cont">
          <h5 class="wtop">特色产品</h5>
          <img src="../assets/image/173.png">
        </div>

      </div> -->

    <!-- 意见反馈 -->
    <div class="liuyan-box">
      <div class="liuyan">
        <div class="title">意见反馈</div>
        <div class="tips">填写您的电话和E-Mail信息，将有助于我们及时与您取得联系，尽快回复您提出的问题。</div>
        <div class="form-top">
          <el-input v-model="form.name" placeholder="您的姓名：" style="width:388px" />
          <el-input v-model="form.mobile" placeholder="手机号码：" style="width:388px" />
          <el-select v-model="form.feedback_type" placeholder="反馈类型" style="width:388px;">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="form bott">
          <el-input style="width:100%;" type="textarea" :autosize="{ minRows: 8, maxRows: 8 }"
            placeholder="简单描述您的需求，我们会尽快联系您" v-model="form.conent">
          </el-input>
        </div>
        <div class="form-btn">
          <div class="tijiao btn" @click="tijiao">
            提交
          </div>
          <div class="chongzhi btn" @click="chongzhi">
            重置
          </div>
        </div>
      </div>
    </div>

    <!-- 友情链接 -->
    <div class="link">
      <div class="link-cont">
        <h5 class="wtop">友情链接 <br> <span>Links</span></h5>
        <div class="link-list">
          <!-- <a href="http://www.gov.cn/shuju/jiage/nongchanpin.htm">全国农产品价格</a> -->
          <a href="http://www.linyixian.gov.cn/">临邑县政府</a>
          <a href="http://www.nj110.cn/">农牧110</a>
          <a href="https://www.cnhnb.com/">惠农网</a>
          <a href="https://fresh.jd.com/">京东生鲜</a>
          <a href="http://www.ymt.com/">一亩田</a>
          <a href="http://xblg.41goo.com/">西部绿谷</a>
        </div>
      </div>
    </div>




  </div>
</div></template>
<script>
export default {
  name: "Shouye",

  components: {

  },

  data() {
    return {
      setInterName: null,
      // handleSelect:"",
      activeIndex2: 2,
      fourData: [{
        a: 1
      },
      {
        a: 2
      },
      {
        a: 3
      },
      {
        a: 4
      },
      {
        a: 5
      }
      ],
      gonggaoList: [],
      xianshi: false,
      xianshi1: false,
      dianhua: "投诉电话：",
      phone: "12345",
      loading: true,
      currentIndex: 3, //当前中间imgs数组中index
      centerInfo: '', // 当前中间信息
      startX: '',
      endX: '',
      imgs: [{
        id: '莱因哈特1',
        index: 0,
        cover: require('../assets/image/149.jpg')
      },
      {
        id: '安娜2',
        index: 1,
        cover:

          require('../assets/image/146.jpg')
      },
      {
        id: '卢西奥3',
        index: 2,
        cover: require('../assets/image/147.jpg')
      },
      {
        id: 'DVA4',
        index: 3,
        cover: require('../assets/image/145.png')
      },
      {
        id: '莫伊拉5',
        index: 4,
        cover: require('../assets/image/144.jpg')
      },
      {
        id: '裂空6',
        index: 5,
        cover: require('../assets/image/143.jpg')
      },
      {
        id: '麦克雷7',
        index: 6,
        cover: require('../assets/image/150.jpg')
      },
      ],
      previous: 0,
      config5: [{
        id: '-A',
        position: 'absolute',
        width: '22%',
        height: '67%',
        top: '19.2%',
        left: '-22%',
        opacity: 0,
        zIndex: 0,
        transition: '.4s'
      },
      {
        id: 'A',
        position: 'absolute',
        width: '22%',
        height: '67%',
        top: '19.2%',
        left: '0%',
        opacity: 1,
        zIndex: 1,
        transition: '.4s'
      },
      {
        id: 'B',
        position: 'absolute',
        width: '28%',
        height: '82%',
        top: '14%',
        left: '13%',
        opacity: 1,
        zIndex: 2,
        transition: '.4s'
      },
      {
        id: 'center',
        position: 'absolute',
        width: '45%',
        height: '100%',
        top: '0px',
        left: '50%',
        marginLeft: '-22.5%',
        opacity: 1,
        zIndex: 4,
        transition: '.4s'
      },
      {
        id: 'D',
        position: 'absolute',
        width: '28%',
        height: '82%',
        top: '14%',
        left: '61.8%',
        opacity: 1,
        zIndex: 2,
        transition: '.4s'
      },
      {
        id: 'E',
        position: 'absolute',
        width: '22%',
        height: '67%',
        top: '19.2%',
        left: '78%',
        opacity: 1,
        zIndex: 1,
        transition: '.4s'
      },
      {
        id: 'E+',
        position: 'absolute',
        width: '22%',
        height: '67%',
        top: '19.2%',
        left: '100%',
        opacity: 0,
        zIndex: 0,
        transition: '.4s'
      }
      ],
      imagesbox: [{
        id: 0,
        idView: require("../assets/image/11.jpg")
      },
      {
        id: 1,
        idView: require("../assets/image/12.jpg")
      },
      {
        id: 2,
        idView: require("../assets/image/13.jpg")
      },
      {
        id: 3,
        idView: require("../assets/image/14.jpg")
      },
      {
        id: 4,
        idView: require("../assets/image/15.jpg")
      }
      ],
      imagesBox: [{
        id: 5,
        idView: require("../assets/image/1.png")
      },
      {
        id: 6,
        idView: require("../assets/image/2.png")
      },
      {
        id: 7,
        idView: require("../assets/image/3.png")
      },
      {
        id: 8,
        idView: require("../assets/image/4.png")
      },
      {
        id: 9,
        idView: require("../assets/image/5.png")
      }
      ],
      images: [{
        id: 10,
        img: require("../assets/image/banner1.png")
      },
      {
        id: 11,
        img: require("../assets/image/banner2.png")
      }
      ],
      input: '',
      value: '',
      info: {},
      info1: [],
      info2: [],
      swiperImgs: [],

      /* 新闻资讯 */
      xwzxList: [],
      /* 农技知识 */
      njzsList: [],
      /* 特色地区 */
      tsdqList: [],
      /* 新闻资讯第一篇内容 */
      oneXW: {},
      /* 农技知识第一篇内容 */
      oneNj: {},
      /* 意见反馈表单 */
      form: {},
      /* 意见反馈类型 */
      options: [],
    }
  },

  computed: {
    classOption() {
      return {
        step: 0.3, //数值越大速度滚动越快
        limitMoveNum: 1, //开始无缝滚动的数据量  //this.fourDatata.length
        hoverStop: true, //是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, //开启数据实时监控刷新dom
        singleHeight: 10, //单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, //单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 //单步运动停止的时间(默认值1000ms)
      };
    },

  },

  created() {
    this.getData();
    // this.centerCard(); // 获取中间卡片信息
    // this.addCardStyle(); // 加入样式位置的index
    // this.getgonggaoList() //获取公告列表
  },

  mounted: function () {
    this.getSwiperImg()
    // this.getshuju()
    // this.getshuju1()
    // this.getshuju2()
    // this.getshuju3()
    // this.setInterName = setInterval(() => {
    //   this.next();
    // }, 3000)
    this.init()
  },

  beforeDestroy() {
    clearInterval(this.setInterName)
  },

  methods: {

    toTese(article_id) {
      this.$router.push({ path: '/diqu', query: { article_id: article_id } })
    },

    toZixun(article_id) {
      this.$router.push({ path: '/zixun', query: { article_id: article_id } })
    },

    toNoji(article_id) {
      this.$router.push({ path: '/zhishi', query: { article_id: article_id } })
    },


    init() {
      let that = this
      that.$http.get('/pages/article-categories?category_type=' + 'NOTICE')
        .then(function (response) {
          let rdata = response.data.children
          let arr = []
          rdata.forEach(item => {
            arr.push(...item.articles)
          });
          arr.forEach(item => {
            item.time = that.filterTime(Number(item.create_time))
          })
          that.xwzxList = arr
          if (arr.length > 0) {
            that.getArticlesXW(arr[0].article_id)
          }
        })
        .catch(function (error) {
          console.log(error, "出错");
        });

      that.$http.get('/pages/article-categories?category_type=' + 'NONGJI')
        .then(function (response) {
          let rdata = response.data.children
          let arr = []
          rdata.forEach(item => {
            arr.push(...item.articles)
          });
          arr.forEach(item => {
            item.time = that.filterTime(Number(item.create_time))
          })
          that.njzsList = arr
          if (arr.length > 0) {
            that.getArticlesNJ(arr[0].article_id)
          }
        })
        .catch(function (error) {
          console.log(error, "出错");
        });

      // 特色地区
      that.$http.get('/pages/article-categories?category_type=' + 'TESE')
        .then(function (response) {
          let rdata = response.data.children
          let arr = []
          rdata.forEach(item => {
            if (item.articles && item.articles.length > 0) {
              arr.push(...item.articles)
            }
          });
          arr.forEach(item => {
            item.time = that.filterTime(Number(item.create_time))
          })
          that.tsdqList = arr
        })
        .catch(function (error) {
          console.log(error, "出错");
        });

      //类型字典
      that.$http.get('/system/dict/getByCode/' + 'FANKUITYPE')
        .then(function (response) {
          that.options = response.data.dict_values
        })
        .catch(function (error) {
          console.log(error, "出错");
        });
    },

    //获取新闻文章详情
    getArticlesXW(id) {
      let that = this
      that.$http.get('/pages/articles/' + id)
        .then(function (response) {
          that.oneXW = response.data
        })
        .catch(function (error) {
          console.log(error, "出错");
        });
    },
    //获取农技文章详情
    getArticlesNJ(id) {
      let that = this
      that.$http.get('/pages/articles/' + id)
        .then(function (response) {
          that.oneNj = response.data
          console.log('------------------t详情t', that.articles);
        })
        .catch(function (error) {
          console.log(error, "出错");
        });
    },

    // 提交意见反馈
    tijiao: function () {
      this.$http.post('/systems/feedback', { ...this.form }).then((res) => {
        if (res.status === 200) {
          this.form = {}
          this.$message({
            message: '提交成功！',
            type: 'success'
          });
        }
      })
    },
    // 重置意见反馈
    chongzhi: function () {
      this.form = {}
    },



    filterTime(time) {
      const date = new Date(time * 1000)
      const Y = date.getFullYear()
      const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
      const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      return `${Y}-${M}-${D}`
    },

    getSwiperImg() {
      let that = this
      that.$http.get('/focus-pictures?client_type=PC')
        .then(function (response) {
          that.swiperImgs = response.data
        })
        .catch(function (error) {
          console.log(error, "出错");
        });
    },

    damaoqi: function () {
      this.$router.push({
        path: '/damaoqi'
      })
    },
    xinwen: function () {
      this.$router.push({
        path: "/xinwen"
      })
    },
    guanyu: function () {
      this.$router.push({
        path: "/guanyu"
      })
    },
    qiye: function () {
      this.$router.push({
        path: "/qiye"
      })
    },
    ruzhu: function () {
      this.$router.push({
        path: "/ruzhu"
      })
    },
    jianyi: function () {
      this.$router.push({
        path: "/jianyi"
      })
    },
    suyuan: function () {
      var a = this.input
      if (a !== '') {
        this.$http.post('dmq/dmq/selectSymById', {
          suyuanma: a
        }).then((res) => {
          if (res.data.result == "无此溯源码") {
            this.$message("无此溯源码")
            this.input = ''
          } else {
            this.$router.push({
              name: "zhuisu",
              query: {
                a
              }
            })
            this.input = ''
          }
        })
      } else {
        this.$message('请输入21位正确的溯源码');
      }
    },
    suyuan1: function () {
      var a = this.value
      if (a != "") {
        this.$http.post('dmq/dmq/selectSymById', {
          suyuanma: a
        })
          .then((res) => {
            console.log(res)
            if (res.data.result == "无此溯源码") {
              this.$message("无此溯源码")
              this.value = ''
            } else {
              console.log(a);
              this.$router.push({
                name: "zhuisu",
                query: {
                  a
                }
              })
              this.value = ''
            }
          })
      } else {
        this.$message('请输入21位正确的溯源码');
      }

    },
    getgonggaoList() {
      var that = this
      this.$http.get('ststistical/global/Announcements')
        .then(function (response) {
          that.gonggaoList = response.data.data
        })

    },
    getshuju() {
      var that = this
      this.$http.get('platform/gjzc/list')
        .then(function (response) {
          var a = response.data.rows
          var b = a.slice(0, 4)
          // this.data.info=response.data.pd
          that.info = b
        })
    },
    getshuju1() {
      var that = this
      this.$http.get('platform/nszx/list')
        .then(function (response) {
          var a = response.data.rows
          var b = a.slice(0, 4)
          // this.data.info=response.data.pd
          that.info1 = b
        })
    },
    getshuju2() {
      var that = this
      this.$http.get('platform/tzgg/list')
        .then(function (response) {
          var a = response.data.rows
          var b = a.slice(0, 4)
          // this.data.info=response.data.pd
          that.info2 = b

        })
    },
    getshuju3() {
      var that = this
      this.$http.post('platform/source/companys')
        .then(function (res) {
          console.log(res);
          that.fourData = res.data.data
        })
    },
    tiaozhuan(id) {
      this.$router.push({
        path: "/QiyeVal",
        query: {
          id
        }
      })
    },


    //获取投诉电话
    tousu() {
      var that = this
      this.$http.post('dmq/guhua/list')
        .then(function (res) {
          that.phone = res.data.rows[0].guhua
        })
      that.xianshi = true
    },
    guanbi() {
      var that = this
      that.xianshi = false
    },
    shexiang() {
      var that = this
      that.xianshi1 = true
    },
    guanbi1() {
      var that = this
      that.xianshi1 = false
    },

    getMdia() {
      let constraints = {
        video: {
          width: 500,
          height: 500
        },
        audio: false
      };
      let video = document.getElementById("myVideo");
      let promise = navigator.mediaDevices.getUserMedia(constraints);
      promise.then(function (MediaStream) {
        video.srcObject = MediaStream;
        video.play();
      });
    },


    async getData() {
      this.$nextTick(() => {
        this.loading = false;
      });
    },
    // 滑动上一个
    prev() {
      // this.imgs.unshift(this.imgs.pop());
      this.config5.push(this.config5.shift());
      this.currentIndex = this.currentIndex - 1;
      if (this.currentIndex < 0) {
        this.currentIndex = this.imgs.length - 1;
      }
      this.centerCard();
      this.centerIndex('prev');
    },
    // 滑动下一个
    next() {
      // this.imgs.push(this.imgs.shift());
      this.config5.unshift(this.config5.pop());
      this.currentIndex = this.currentIndex + 1;
      if (this.currentIndex > this.imgs.length - 1) {
        this.currentIndex = 0;
      }
      this.centerCard();
      this.centerIndex('next');
      // console.log(this.currentIndex);
    },
    // 开始移动端滑动屏幕
    start(event) {
      this.startX = event.changedTouches[0].clientX;
      this.startY = event.changedTouches[0].clientY;
    },
    // 连续滑动
    move(event) {
      this.endY = event.changedTouches[0].clientY;
      this.endX = event.changedTouches[0].clientX;
      this.stopDefault(event);
      // 如果是滑动，注解（223行到231行）这段。如果是连续滑动，放开（223行到231行）注解
      this.interval = this.endX - this.startX;
      if (this.interval > 40) {
        this.startX = this.endX;
        this.prev();
      }
      if (this.interval < -40) {
        this.startX = this.endX;
        this.next();
      }
    },

    // 滑动
    end() {
      // 如果是滑动，放开（236行到238行）的注解。如果是连续滑动，注解（236行到238行）
      // this.endY = event.changedTouches[0].clientY;
      // this.endX = event.changedTouches[0].clientX;
      // this.formatSwiper();
    },
    formatSwiper() {
      if (this.startX > this.endX) {
        if (this.startX > this.endX + 40) {
          this.next();
        }
      } else {
        if (this.endX > this.startX + 40) {
          this.prev();
        }
      }
    },
    // 阻止touchmove的横向默认事件（ios快捷操作会关闭页面）
    stopDefault(event) {
      let differenceY = this.endY - this.startY;
      let differenceX = this.endX - this.startX;
      if (Math.abs(differenceX) > Math.abs(differenceY)) {
        event.preventDefault();
      }
    },
    // 当前imgs在位置上的index（并非img数组的index）
    centerIndex(val) {
      if (val == 'prev') {
        for (let val of this.imgs) {
          if (val.index == this.imgs.length - 1) {
            val.index = 0;
          } else {
            val.index = val.index + 1;
          }
        }
      } else {
        for (let val of this.imgs) {
          if (val.index == 0) {
            val.index = this.imgs.length - 1;
          } else {
            val.index = val.index - 1;
          }
        }
      }
    },
    // 点击
    chooseItem(item) {
      let cycles = item.index;
      if (item.index < 3) {
        for (let i = 0; i < 3 - cycles; i++) {
          this.prev();
        }
      } else if (item.index > 3) {
        for (let i = -1; i < item.index - 3; i++) {
          this.next();
        }
      } else if (item.index == 3) {
        console.log('');
      }
    },
    // 计算中间卡片信息
    centerCard() {
      this.centerInfo = this.imgs[this.currentIndex];
      this.$emit('centerInfo', this.centerInfo);
      // this.$emit('centerInfo', this.centerInfo);
      // console.log(this.imgs[2].id);
    },

    addCardStyle() {
      if (this.imgs.length > 7) {
        let addtime = this.imgs.length - 7;
        for (let i = 0; i < addtime; i++) {
          this.config5.push({
            id: 'center',
            position: 'absolute',
            width: '45%',
            height: '100%',
            top: '0px',
            left: '50%',
            marginLeft: '-22.5%',
            opacity: 0,
            transition: '.1s'
          });
        }
      }
    }



    //    nongshi:function(id){
    //        console.log(id)
    //        this.$router.push({path:"/XinwenVal",query:{id}})
    //    }
  }
};
</script>

<style scoped>
.main {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

/* 关于我们 */
.guanyu-box {
  width: 100%;
  background-image: url(../assets/newImg/636459241765254237.jpg);
  background-repeat: no-repeat;
  background-position: 50% 0;
  padding: 50px 0px 50px 0px;
  overflow: hidden;
  margin-bottom: 40px;
}

.gu_01{
  width: 360px;
  height: 250px;
  background-image: url(../assets/001.png);
  background-size: auto 100%  ;
}

.gu_02{
  width: 360px;
  height: 250px;
  background-image: url(../assets/022.png);
  background-size: 100% auto ;
}

.gu_03{
  width: 360px;
  height: 250px;
  background-image: url(../assets/003.png);
  background-size:100%  auto  ;
}

.guanyu {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}


.guanyu .bott {
  display: flex;
  width: 100%;
  background-color: #f6aa26;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 30px;
  /* overflow: hidden; */
  /* margin-top: 30px; */
}

.guanyu .bott img {
  width: 360px;
  height: 250px;
}

/* 特色地区 */
.diqu {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 30px;
}

.diqulist {
  display: grid;
  grid-template-columns: repeat(5, 228px);
  justify-content: space-between;
}

.diqulist .item {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all .5s;
  padding: 5px 10px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.diqulist .item .img {
  width: 208px;
  height: 208px;
  overflow: hidden;
}

.diqulist .item img {
  width: 208px;
  height: 208px;
  transition: all .5s;
}

.diqulist .item .div {
  width: 208px;
  height: 42px;
  line-height: 42px;
  font-size: 12px;
  background-color: #fafafa;
  text-align: center;
  transition: all .5s;

}

.diqulist .item:hover {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2)
}

.diqulist .item:hover img {
  transform: scale(1.2);
}

.diqulist .item:hover .div {
  background-color: #52bb9d;
  color: #FFF;
}

/* 意见反馈 */
.liuyan-box {
  width: 100%;
  background-image: url(../assets/newImg/636463640691406858.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 0px auto 0px auto;
  padding: 50px 0px 45px 0px;
  overflow: hidden;
}

.liuyan {
  width: 1200px;
  margin: 0 auto;
}

.liuyan .title {
  display: block;
  margin: 0 auto;
  min-width: 290px;
  height: 82px;
  background: url(../assets/newImg/titlebg.png) no-repeat center;
  color: #333;
  font-size: 28px;
  font-weight: 100;
  line-height: 82px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: top;
  text-align: center;
}

.liuyan .tips {
  margin: 10px 0 20px;
  color: #8b8b8b;
  line-height: 46px;
  font-size: 14px;
  width: 100%;
  text-align: center;
}

.liuyan .form-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.liuyan .form-btn {
  display: flex;
  justify-content: space-between;
  padding-top: 14px;
}

.liuyan .form-btn .btn {
  width: 589px;
  flex-shrink: 0;
  line-height: 44px;
  text-align: center;
  font-size: 18px;
  color: #FFF;
  cursor: pointer;
}

.liuyan .form-btn .tijiao {
  background: #52bb9d;
  transition: all .5s;
}

.liuyan .form-btn .tijiao:hover {
  background: #f6aa26;
}

.liuyan .form-btn .chongzhi {
  background: #122922;
  transition: all 1s;
}

.liuyan .form-btn .chongzhi:hover {
  background: #000f16;
}



/* 新闻 知识 */
.block {
  width: 590px;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.block .title,
.diqu .title,
.jigou .title {
  height: 41px;
  min-height: 41px;
  background: url(../assets/newImg/icon_3.jpg) repeat-x;
  line-height: 41px;
  margin-bottom: 20px;
}

.block .title .more,
.diqu .title .more,
.jigou .title .more {
  float: right;
  margin-right: 18px;
  padding-right: 13px;
  background: url(../assets/newImg/ifcb_sort_ico3.png) no-repeat right center;
  font-size: 12px;
  color: #000;
}

.block .title .title_more,
.diqu .title .title_more,
.jigou .title .title_more {
  position: relative;
  float: left;
  min-width: 146px;
  padding: 0 5px;
  background: url(../assets/newImg/icon_4.jpg) no-repeat;
  color: #494949;
  text-align: center;
  border-top-right-radius: 12px;
}

.block .content .line-one {
  display: flex;
  margin-bottom: 30px;
}

.block .content .line-one:hover .img img {
  transform: scale(1.2);
}

.block .content .line-one:hover .btn {
  background-color: #f6aa26;
}

.line-one .img {
  width: 236px;
  height: 236px;
  flex-shrink: 0;
  overflow: hidden;
  background-color: #FFF;
}

.line-one .img img {
  width: 236px;
  height: 236px;
  transition: all .5s;
}

.line-one .one-right {
  box-sizing: border-box;
  background-color: #f7f7f7;
  padding: 20px 30px 30px;
  width: 100%;
}

.line-one .one-right .titler {
  font-size: 16px;
  line-height: 40px;
  color: #3b3b3b;
}

.line-one .one-right .cont {
  color: #999;
  font-size: 12px;
  line-height: 22px;
  margin: 12px 0 24px;
}

.line-one .one-right .btn {
  display: block;
  background-color: #52bb9d;
  color: #fff;
  width: 180px;
  line-height: 40px;
  text-align: center;
  transition: all .5s;
  cursor: pointer;
}



.block .content .line {
  padding: 10px 20px;
  border: 1px solid #eee;
  color: #999;
  border-bottom: 0;
  display: flex;
  align-items: center;
  position: relative;
  transition: all .5s;
  cursor: pointer;
  box-sizing: border-box;
}

.block .content .line:hover {
  background-color: #52bb9d;
  color: #FFF;
  padding-left: 30px;
}

.block .content .line:nth-last-child(1) {
  border-bottom: 1px solid #eee;
}

.block .content .line .xian {
  font-weight: bold;
  font-size: 30px;
  margin-right: 12px;
}

.block .content .line .timer {
  position: absolute;
  right: 20px;
}


/* 友情链接 */
.link {
  padding: 40px 0;
  background: url(../assets/newImg/mainbg.jpg);
  background-size: 100% 100%;
}

.link-cont {
  overflow: hidden;
  width: 1200px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 30px;
  background: #fff;
  border-bottom: 1px solid #f1f1f1;
}

.wtop {
  font-weight: 400;
  padding-left: 10px;
  background: url(../assets/newImg/line2.png) no-repeat left center;
  font-size: 18px;
  color: #333;
  text-decoration: none;
  margin: 10px 0;
}

.link .link-list {
  display: flex;
  align-items: center;
}

.link .link-list a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 20px;
  font-size: 14px;
  color: #333;
}

.jigou {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 40px;
}


/* 合作机构 */
.hezuo {
  width: 100%;
  height: 170px;
  background-color: #54bb9e;
}

.hezuo-left {
  font-size: 34px;
  color: #fff;
  text-decoration: none;
  flex-shrink: 0;
}

.hezuo-cont {
  height: 100%;
  width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.hezuo-cont .flex {
  flex: 1;
  display: flex;
  justify-content: center;
  border-right: 1px solid #77dec1;
  height: 80px;
  align-items: center;
  color: #FFF;
}

.hezuo-cont .flex:nth-last-child(1) {
  border: none;
}

.hezuo-cont .flex img {
  width: 140px;
}


a {
  text-decoration: none;
}

.banner {
  width: 100%;
  height: 550px;
  /* border: 1px solid #333; */
}

.banner img {
  width: 100%;
  height: 100%;
}

.caidan {
  position: absolute;
  top: 70px;
  right: 110px;
  font-weight: 800;
}

/* .toubu {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
} */

.el-menu-demo {
  text-align: center;
}

.herder {
  font-size: 30px;
  color: #71ae47;
  position: absolute;
  top: 6px;
  left: 190px;
}

.herder1 {
  width: 350px;
  height: 40px;
  position: absolute;
  top: 55px;
  left: 190px;
}

.pingtai {
  width: 100%;
  height: 100px;
  background-image: url("../assets/image/155.png");
  background-size: 100% 100%;
}

.list {
  width: 100%;
  height: 60px;
}

.list ul {
  width: 100%;
  height: 100%;
}

.list ul li {
  width: 14%;
  height: 100%;
  font-size: 20px;
}

.sousuo {
  width: 550px;
  height: 80px;
  position: absolute;
  top: 27%;
  left: 33%;
}

.sousuo div {
  width: 400px;
  height: 70px;
  float: left;
}

.sousuo>div>input {
  width: 100%;
  height: 100%;
}

.sousuo button {
  width: 120px;
  height: 40px;
  float: left;
}

.logo {
  width: 70px;
  height: 400px;
  position: fixed;
  top: 33%;
  right:4%;
  z-index: 99;
}

.logo>img {
  display: block;
  margin-top: 20px;
  width: 60px;
}

.logo>a {
  display: block;
  margin-top: 20px;
}

.logo1 {
  position: fixed;
  background-color: rgba(255, 255, 255, 1);
  top: 25%;
  left: 4%;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
}

.tousu {
  width: 600px;
  height: 400px;
  border: 1px solid #333;
  position: absolute;
  top: 30%;
  left: 30%;
  z-index: 1000;
  background-image: url("../assets/image/156.png");
  background-size: 100% 100%;
}

.tousu div {
  width: 150px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  color: #02adff;
  margin-top: 100px;
  margin-left: 150px;
}

.guanbi {
  margin-left: 557px;
  margin-top: 5px;
}

.guanbi1 {
  width: 50px;
  height: 22px;
  margin-left: 450px;
}

.videoArea {
  width: 500px;
  height: 400px;
  border: 1px solid #ccc;
  position: absolute;
  top: 25%;
  left: 30%;
  background-color: #ffffff;
}

.videoArea>video {
  height: 300px;
  width: 300px;
}

.canvasArea {
  width: 500px;
  height: 500px;
  border: 1px solid #ccc;
}

.value-bg {
  width: 1100px;
  /* height: 2100px; */
  margin: 0 auto;
  /* background-color: #fbf8f8; */
}

.xinwen-logo {
  width: 1020px;
  height: 60px;
  margin: 0 auto;
  background-color: #fafbf4;
  border-bottom: 1px solid #cbcbcb;
}

.xinwen-logo .xinwen-img {
  width: 150px;
  height: 40px;
  margin-left: 30px;
  margin-top: 10px;
}

.ruzhu {
  width: 290px;
  height: 200px;
  /* border: 1px solid #222; */
  margin-left: 10px;
  float: left;
  border-top: 3px solid #6fb341;
  border-left: 1px solid #a5a6a2;
  border-bottom: 1px solid #a5a6a2;
  border-right: 1px solid #a5a6a2;
  background-color: #fff;
}

.ruzhu-img {
  width: 290px;
  height: 55px;
  background-color: #fafbf4;
  border-bottom: 1px solid #cdcdcd;
}

.ruzhu-value {
  width: 290px;
  height: 140px;
}

.warp {
  height: 229px;
  overflow: hidden;
}

.warp .gonggaoList {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.lunbo4 {
  width: 100%;
  height: 250px;
  margin: 30px auto;
}

.rukou-img {
  width: 1020px;
  height: 57px;
  background-color: #fdfef6;
  border-bottom: 1px solid #cdcdcd;
}


.lunbo {
  width: 1030px;
  height: 300px;
  margin: 473px auto;
}

.kongbai {
  width: 100%;
  height: 140px;
}

.gonggao {
  width: 1090px;
  height: 50px;
  margin: 0 auto;
  display: flex;
  overflow: hidden;
  align-items: center;
}

.gonggao img {
  float: left;
}

.gonggao div {
  float: left;
  color: #333;
}

.xinwen {
  width: 1020px;
  height: 340px;
  margin: 0 auto;
  border-top: 3px solid #6fb341;
  border-left: 1px solid #a5a6a2;
  border-bottom: 1px solid #a5a6a2;
  border-right: 1px solid #a5a6a2;
}

.zixun {
  width: 1020px;
  height: 269px;
  display: flex;
  justify-content: space-evenly;
  margin: 0 auto;
  background-color: #fff;
  padding-top: 10px;
}

.zixun .lunbo3 {
  width: 500px;
  height: 400px;
}

.zixun .tab {
  width: 400px;
  height: 260px;
}

.el-tabs--top {
  width: 100%;
  height: 100%;
}

.zixun1 {
  width: 370px;
  height: 50px;
  display: flex;
  justify-content: space-between;
}

/* .jianjie_bg{
       width: 100%;
       height: 400px;
        background-color: #f9f9f9;
   } */
.jianjie {
  width: 1020px;
  height: 400px;
  margin: 0 auto;
  margin-top: 10px;
}

.pingtai_jianjie {
  width: 360px;
  height: 200px;
  float: left;
  border-top: 3px solid #6fb341;
  border-left: 1px solid #a5a6a2;
  border-bottom: 1px solid #a5a6a2;
  border-right: 1px solid #a5a6a2;
  background-color: #fff;
}

.pingtai_jianjie1 {
  width: 360px;
  height: 45px;
  padding-top: 0px;
  border-bottom: 1px solid #cbcbcb;
  background-color: #fafbf4;
}

.pingtai_jianjie .wenzi {
  text-indent: 20px;
  width: 349px;
  height: 110px;
  font-size: 14px;
  /* margin-left: 60px; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  /*截取4行*/
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 10px;
}

.chaxun {
  width: 340px;
  height: 200px;
  float: left;
  /* padding-left: 10px; */
  margin-left: 10px;
  border-top: 3px solid #6fb341;
  border-left: 1px solid #a5a6a2;
  border-bottom: 1px solid #a5a6a2;
  border-right: 1px solid #a5a6a2;
  background-color: #fff;
}

.chaxun-img {
  width: 340px;
  height: 45px;
  padding-top: 10px;
  border-bottom: 1px solid #cbcbcb;
  background-color: #fafbf4;
}

.fangwei {
  width: 1100px;
  background-color: #fff;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
}


.chaxun1 {
  width: 300px;
  height: 130px;
  position: relative;
  background-image: url("../assets/image/ff9900.png");
  padding-left: 20px;
  margin-top: 10px;
  margin-left: 10px;
}


.chanxun1 button {
  margin-top: 30px;
}

.fangwei2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fangwei3,
.fangwei1 {
  text-align: center;
}


.jianguan {
  padding: 40px 0;
  /* background: url(../assets/newImg/mainbg.jpg); */
  background-color: #FFF;
  background-size: 100% 100%;
}

.jigoulist {
  width: 950px;
  height: 325px;
  margin: 20px auto;
  position: relative;
}

.jigoulist .tu1 {
  position: absolute;
  top: 0px;
  left: 240px;
}

.jigoulist .tu2 {
  position: absolute;
  top: 0px;
  left: 510px;
}

.jigoulist .tu3 {
  position: absolute;
  top: 0px;
  left: 780px;
}

.jigoulist .tu5 {
  position: absolute;
  top: 160px;
  left: 240px;
}

.jigoulist .tu6 {
  position: absolute;
  top: 160px;
  left: 510px;
}

.jigoulist .tu7 {
  position: absolute;
  top: 160px;
  left: 780px;
}

.jigoulist .tu8 {
  position: absolute;
  top: 80px;
  left: 110px;
}

.jigoulist .tu9 {
  position: absolute;
  top: 80px;
  left: 380px;
}

.jigoulist .tu10 {
  position: absolute;
  top: 80px;
  left: 650px;
}

.rukou {
  width: 1020px;
  height: 210px;
  margin: 0 auto;
  border-top: 3px solid #6fb341;
  border-left: 1px solid #a5a6a2;
  border-bottom: 1px solid #a5a6a2;
  border-right: 1px solid #a5a6a2;
  margin-top: 10px;
}

.rukou .rukoudizhi {
  width: 1010px;
  height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding-right: 10px;
}

.rukoudizhi a {
  width: 150px;
  height: 80px;
}

.rukoudizhi>a>img {
  width: 135px;
  height: 80px;
}


.swiper {
  width: 70%;
  /* border: 1px red solid; */
  height: 250px;
  position: relative;
  top: -125px;
  left: 16%;
  overflow: hidden;
}

.swiper div {
  opacity: 0;
}</style>
