<template>
  <div>
    <!-- <Caidan></Caidan> -->
    <div class="banner">
      <img src="../assets/guanyu/22.png"
           alt="">
    </div>
    <div class="rongyu1">
      <div class="rongyu">
        <img src="../assets/guanyu/2.png"
             alt="">
        <div class="zizhi1">
          <img class="zizhi2"
               src="../assets/guanyu/timg.jpg" />
          <img class="zizhi3"
               src="../assets/guanyu/timg.jpg" />
          <img class="zizhi4"
               src="../assets/guanyu/timg.jpg" />
          <img class="zizhi5"
               src="../assets/guanyu/timg.jpg" />
        </div>
      </div>
    </div>
    <div class="mubiao">
      <img src="../assets/guanyu/3.png"
           alt="">
      <div class="mubiao1">
        <div>
          <img src="../assets/guanyu/4.png"
               alt="">
          <div class="wenzi">
            <p>保障农产品质量安全</p>
            <p style="font-size:10px;width:150px;height:15px;">实现一物一码，使得农资产品生产有记录,
              使得农资产品生产有记录使得农资产品生产有记录</p>
          </div>

        </div>
        <div>
          <img src="../assets/guanyu/5.png"
               alt="">
          <div class="wenzi1">
            <p>降低农资成本</p>
            <p style="font-size:10px;width:150px;height:15px;">建设平台系统，提升农资
              水平，有效降低农资成本</p>
          </div>

        </div>
        <div>
          <img src="../assets/guanyu/6.png"
               alt="">
          <div class="wenzi2">
            <p>提升农资企业管理水平</p>
            <p style="font-size:10px;width:150px;height:15px;">提供追溯平台系统，促使传统农资
              企业转型</p>
          </div>

        </div>
        <div>
          <img src="../assets/guanyu/7.png"
               alt="">
          <div class="wenzi3">
            <p>保障农产品质量安全</p>
            <p style="font-size:10px;width:150px;height:15px;">追溯平台可有效关注农产
              品质量安全</p>
          </div>
        </div>
      </div>
    </div>
    <div class="lianxi">
      <img src="../assets/guanyu/8.png"
           alt="">
      <div class="logo">
        <div class="dianhua">
          <img src="../assets/guanyu/9.png"
               alt="">
          <p>电话:0534-4710111</p>
        </div>
        <div class="dizhi">
          <img src="../assets/guanyu/10.png"
               alt="">
          <p>山东省德州市临邑县临南镇</p>
        </div>
        <div class="youxiang">
          <img src="../assets/guanyu/11.png"
               alt="">
          <p>lnzrmzf@dz.shandong.cn</p>
        </div>
      </div>
    </div>
    <div class="weizhi">
      <div id="container">
      </div>
    </div>

  </div>
</template>
<script>
export default {
  computed: {
    mapAddress () {
      return "达茂旗"
    }
  },

  watch: {
    mapAddress () {
      this.loadScript()
    }
  },

  mounted () {

    this.loadScript()


  },

  methods: {
    loadScript () {
      window.initMap = () => {
        console.log(1)
        this.initMap()
      }
      if (!window.BMap) {
        const script = document.createElement('script')
        script.src = 'http://api.map.baidu.com/api?v=2.0&ak=itq41hB0USBoLG6Gai6hKE35yYCVAPiL&callback=initMap'
        document.body.appendChild(script)
      }
      else {
        this.initMap()
      }
    },
    initMap () {
      console.log(1 - 2)
      var map = new window.BMap.Map("container");    // 创建Map实例
      console.log(map)
      var localSearch = new window.BMap.LocalSearch(map);
      localSearch.setSearchCompleteCallback((searchResult) => {
        var poi = searchResult.getPoi(0);
        if (poi === undefined) {
          this.noMap = false
          return
        }
        var point = new window.BMap.Point(poi.point.lng, poi.point.lat);
        var marker = new window.BMap.Marker(point);  // 创建标注
        map.addOverlay(marker);              // 将标注添加到地图中
        map.enableScrollWheelZoom(true)
        map.centerAndZoom(point, 18);
        setTimeout(() => {
          map.clearOverlays();
          var marker = new window.BMap.Marker(point);
          map.addOverlay(marker);
        }, 4000)
      });
      localSearch.search(this.mapAddress);
    }
  }
};
</script>

<style scoped>
.pingtai {
  width: 1;
  height: 100px;
  position: relative;
}
.herder {
  font-size: 30px;
  color: #71ae47;
  position: absolute;
  top: 38px;
  left: 190px;
}
.caidan {
  position: absolute;
  top: 80px;
  right: 50px;
}
.caidan div {
  display: inline;
  padding-left: 30px;
  color: #009966;
}
.el-menu-demo {
  display: flex;
  justify-content: center;
}
.el-menu-item {
  font-size: 20px;
  width: 200px;
  height: 50px;
}
.banner {
  width: 100%;
  height: 600px;
}
.banner img {
  width: 100%;
  height: 100%;
}
.rongyu1 {
  width: 100%;
  height: 300px;
  background-color: #fcfcfc;
}
.rongyu {
  width: 1090px;
  height: 300px;
  background: #fcfcfc;
  margin: 0 auto;
  position: relative;
}
.zizhi1 {
  width: 800px;
  height: 250px;
  position: absolute;
  top: 90px;
  left: 105px;
}
.zizhi1 .zizhi2 {
  position: absolute;
  top: 0px;
  left: -10px;
}
.zizhi1 .zizhi3 {
  position: absolute;
  top: 0px;
  left: 190px;
}
.zizhi1 .zizhi4 {
  position: absolute;
  top: 0px;
  left: 390px;
}
.zizhi1 .zizhi5 {
  position: absolute;
  top: 0px;
  left: 590px;
}
.rongyu img {
  position: absolute;
  top: 30px;
  left: 385px;
}
.mubiao {
  width: 1210px;
  height: 250px;
  margin: 20px auto;
  position: relative;
}
.mubiao > img {
  position: absolute;
  top: 30px;
  left: 450px;
}
.mubiao1 {
  width: 1210px;
  height: 200px;
  margin: 0px auto;
  position: absolute;
  top: 100px;
}
.mubiao1 > div {
  width: 300px;
  height: 150px;
  float: left;
}
.wenzi {
  position: absolute;
  top: -10px;
  left: 110px;
}
.wenzi1 {
  position: absolute;
  left: 410px;
  top: -10px;
}
.wenzi2 {
  position: absolute;
  left: 710px;
  top: -10px;
}
.wenzi3 {
  position: absolute;
  left: 1010px;
  top: -10px;
}
.lianxi {
  width: 820px;
  height: 200px;
  margin: 0 auto;
  position: relative;
}
.lianxi img {
  position: absolute;
  top: 0px;
  left: 265px;
}
.logo {
  width: 800px;
  height: 200px;
  position: absolute;
  top: 45px;
  left: 0px;
}
.logo > div {
  width: 260px;
  height: 190px;
  float: left;
}
.dianhua {
  position: relative;
}
.dianhua img {
  position: absolute;
  top: 20px;
  left: 90px;
}
.dianhua p {
  position: absolute;
  top: 100px;
  left: 60px;
  color: #7a7a7a;
}
.dizhi {
  position: relative;
}
.dizhi img {
  position: absolute;
  top: 20px;
  left: 90px;
}
.dizhi p {
  position: absolute;
  top: 100px;
  left: 100px;
  color: #7a7a7a;
}
.youxiang {
  position: relative;
}
.youxiang img {
  position: absolute;
  top: 20px;
  left: 90px;
}
.youxiang p {
  position: absolute;
  top: 100px;
  left: 60px;
  color: #7a7a7a;
}
.weizhi {
  width: 650px;
  height: 250px;
  margin: 0 auto;
}
#container {
  width: 600px;
  height: 250px;
}
.banquan {
  position: relative;
}

.xinxi div {
  flex: 1;
}
.xinxi div img {
  float: left;
  margin-top: 3px;
}
.xinxi div p {
  float: left;
  line-height: 0px;
  color: #ffffff;
}
/* .banquan{
     font-size: 12px;
     color: #ffffff;
     position: absolute;
     top: 20px;left: 43%;
 } */
</style>
