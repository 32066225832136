<template>
  <div id="app">
    <Caidan></Caidan>

    <router-view>
    </router-view>
    <Footer></Footer>

  </div>
</template>
<script>


export default {
  name: 'App',
}
</script>

<style>
body{
  margin: 0;
}
</style>
