<template>
  <div class="concent1">
    <div class="banner">
      <img src="../assets/zu25.png" alt="">
    </div>
    <div class="main">
      <div class="content">
        <el-row>
          <el-col :span="5">
            <div class="aside" v-for="menu in dataList" :key="menu.id">
              <div class="title">
                <div class="title_more"> {{ menu.name }} </div>
              </div>

              <div class="newslist">
                <div class="li" v-for="article in menu.articles" :key="article.article_id"
                  @click="handleSelect(article.article_id)">- {{ article.article_name }}</div>
              </div>
            </div>
          </el-col>
          <el-col :span="19">
            <div class="details">
              <h1 class="title">{{ articles.article_name }}</h1>
              <div class="subject-content" v-html="articles.content"></div>
            </div>
          </el-col>
        </el-row>

      </div>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      tabPosition: 'left',
      dataList: [],
      articles: {}
    };
  },
  mounted: function () {
    this.getCategories()
  },
  methods: {
    getCategories() {
      let that = this
      that.$http.get('/pages/article-categories?category_type=' + 'MINGYOUQIYE')
        .then(function (response) {
          let rdata = response.data.children
          that.dataList = rdata
          if (rdata.length > 0 && rdata[0].articles.length > 0) {
            let id = rdata[0].articles[0].article_id
            that.getArticles(id)
          }
        })
        .catch(function (error) {
          console.log(error, "出错");
        });
    },

    //获取文章详情
    getArticles(id) {
      let that = this
      that.$http.get('/pages/articles/' + id)
        .then(function (response) {
          that.articles = response.data
          console.log('------------------t详情t', that.articles);
        })
        .catch(function (error) {
          console.log(error, "出错");
        });
    },

    handleSelect(key) {
      this.getArticles(key)
    },
  }
}
</script>

<style>
.details img {
  width: 100%;
}
</style>
<style scoped>
.pingtai {
  width: 1;
  height: 100px;
  position: relative;
}

.herder {
  font-size: 30px;
  color: #71ae47;
  position: absolute;
  top: 38px;
  left: 190px;
}

.caidan {
  position: absolute;
  top: 80px;
  right: 50px;
}

.caidan div {
  display: inline;
  padding-left: 30px;
  color: #009966;
}

.banner img {
  width: 1200px;
  max-height: 300px;
  display: block;
  margin: 0 auto;
}

.main {
  padding-bottom: 10px;
  background: url(../assets/newImg/ifcb_inner_bg.jpg);
}

.content {
  width: 1200px;
  margin: 0 auto;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 20px;
}

.aside .title {
  min-height: 40px;
  background: url(../assets/newImg/location.jpg) repeat-x;
  line-height: 40px;
}

.aside .title .title_more {
  position: relative;
  float: left;
  min-width: 140px;
  padding: 0 0px 0 0px;
  background: url(../assets/newImg/ifcb_lt_news.png) no-repeat right top;
  color: #fff;
  font-size: 14px;
  text-align: center;
}

.aside .newslist {
  margin: 20px 0;
}

.aside .newslist .li {
  padding: 10px 20px;
  border: 1px solid #eee;
  color: #727084;
  border-bottom: 0;
  transition: all 0.5s;
  line-height: 40px;
  font-size: 13px;
  box-sizing: border-box;
}

.aside .newslist .li:nth-last-child(1) {
  border-bottom: 1px solid #eee;
}

.aside .newslist .li:hover {
  padding: 10px 30px;
  background-color: #52bb9d;
  color: #FFF;
}

.details {
  box-sizing: border-box;
  padding: 0 30px;
}

.details .title {
  margin: 0 0 20px;
  padding: 20px 0;
  border-bottom: 1px dotted #ccc;
  color: #333;
  text-align: center;
}

/* .tablist {
  width: 1090px;
  margin: 50px auto;
}

.content>div {
  max-height: 600px;
  box-sizing: border-box;
  padding-left: 20px;
  overflow-y: scroll;
} */
</style>