<template>
  <div class="concent1">
    <Caidan></Caidan>
    <div class="banner">
      <img src="../assets/zu25.png"
           alt="">
    </div>
    <div class="xinwen">
      <img src="../assets/1.png"
           alt="">
    </div>
    <div class="tablist">
      <el-tabs class="list"
               :tab-position="tabPosition"
               @tab-click="nongye"
               style="height: 640px;">
        <el-tab-pane class="content"
                     label="农产品加工">企业供应
          <div class="page"
               v-for="item in info"
               :key="item.id">
            <p class="sanjiao"></p>
            <p @click="tiaozhuan(item.id)"
               style="float:left">{{item.name}}</p>
            <p style="float:right">{{item.rzsj}}</p>
          </div>
        </el-tab-pane>
        <el-tab-pane class="content"
                     tab-click="xmy"
                     label="畜牧类">企业需求
          <div class="page"
               v-for="item in info1"
               :key="item.id+1">
            <p class="sanjiao"></p>
            <p @click="tiaozhuan1(item.id)"
               style="float:left">{{item.name}}</p>
            <p style="float:right">{{item.rzsj}}</p>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="fenye">
      <el-pagination v-if=show
                     class="ncp"
                     background
                     name="first"
                     layout="prev, pager, next"
                     page-size="6"
                     :current_page="pageCount"
                     @current-change="handleCurrentChange"
                     :total="i"
                     currentPage="1">
      </el-pagination>

      <el-pagination v-if=show1
                     class="xmy"
                     background
                     name="second"
                     layout="prev, pager, next"
                     page-size="6"
                     :current_page="pageCount"
                     @current-change="handleCurrentChange1"
                     :total="index"
                     currentPage="1">
      </el-pagination>
    </div>
    <div class="beijing">
      <div class="xinxi">
        <div>
          <img src="../assets/logo/1.png"
               alt="">
          <p style="font-size:12px;margin-left: 10px;">地址:山东省德州市临邑县临南镇</p>
        </div>
        <div>
          <img src="../assets/logo/2.png"
               alt="">
          <p style="font-size:12px;margin-left: 10px;">电话:0534-4710111</p>
        </div>
        <div>
          <img src="../assets/logo/3.png"
               alt="">
          <p style="font-size:12px;margin-left: 10px;">邮编:251500</p>
        </div>
        <div>
          <img src="../assets/logo/4.png"
               alt="">
          <p style="font-size:12px;margin-left: 10px;">邮箱:lnzrmzf@dz.shandong.cn</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  data () {
    return {
      tabPosition: 'left',
      info: [
        {
          id: 1,
          value: "中绿集团",
          time: "2020-04-30"
        },
        {
          id: 2,
          value: "中绿集团",
          time: "2020-04-30"
        },
        {
          id: 3,
          value: "中绿集团",
          time: "2020-04-30"
        },
        {
          id: 4,
          value: "中绿集团",
          time: "2020-04-30"
        },
        {
          id: 5,
          value: "中绿集团",
          time: "2020-04-30"
        },
        {
          id: 6,
          value: "中绿集团",
          time: "2020-04-30"
        },
        {
          id: 7,
          value: "中绿集团",
          time: "2020-04-30"
        },
        {
          id: 8,
          value: "中绿集团",
          time: "2020-04-30"
        }
      ],
      info1: [],
      info2: [],
      info3: [],
      i: "",
      index: "",
      show: true,
      show1: false
    };
  },
  mounted: function () {
    this.getshuju()
    this.getshuju1()
    this.getshuju2()
    //   this.current_change()
  },
  methods: {
    getshuju2 () {
      axios.post("dmq/gxxx/list1")
        .then((res) => {
          console.log(res, "打印数据")
        })
        .catch((req) => {
          console.log(req, "123")
        })
    },
    getshuju () {
      const that = this
      this.$http.post('dmq/rzqy/listPlus')
        .then(function (response) {
          console.log(response);
          var i = response.data.length
          console.log(i)
          that.i = i
          // console.log(response.data.rows)
          // var a = response.data.rows
          // console.log(a)
          // console.log(that)
          that.info = response.data
          that.info2 = response.data
          // console.log(this.data.info)
          // console.log(this.data.info)
        })
        .catch(function (error) {
          console.log(error, "出错了");
        });
    },
    getshuju1 () {
      const that = this
      this.$http.post('dmq/qyrz/listPlus')
        .then(function (response) {
          console.log(response);
          var index = response.data.length
          console.log(index)
          that.index = index
          // console.log(response.data.qyrz)
          // var a = response.data.qyrz
          // console.log(a)
          // console.log(that)
          that.info1 = response.data
          that.info3 = response.data
          // console.log(this.data.info)
          // console.log(this.data.info)
        })
        .catch(function (error) {
          console.log(error, "出错了");
        });
    },
    tiaozhuan (id) {
      console.log("点击了企业", id)
      this.$router.push({ path: "/QiyeVal", query: { id } })
    },
    tiaozhuan1 (id1) {
      console.log(id1)
      this.$router.push({ path: "/QiyeVal", query: { id1 } })
    },
    handleCurrentChange (currentPage) {
      console.log("点击了农产品下的企业")
      var that = this
      console.log(currentPage)
      console.log(this.info2)
      var a = this.info2
      var i = (currentPage - 1) * 6
      var index = 6 * currentPage
      console.log(i, index)
      var b = a.slice(i, index)
      console.log(b)
      that.info = b
      //    console.log("1")
    },
    handleCurrentChange1 (currentPage) {
      console.log("点击了畜牧业下的企业")
      var that = this
      console.log(currentPage)
      console.log(this.info3)
      var a = this.info3
      var i = (currentPage - 1) * 6
      var index = 6 * currentPage
      console.log(i, index)
      var b = a.slice(i, index)
      console.log(b)
      that.info1 = b
    },
    nongye (tab, event) {
      const that = this
      console.log(tab, event)
      console.log(tab.label)
      if (tab.label == "农产品加工") {
        that.show = true
        that.show1 = false
      } else {
        that.show = false
        that.show1 = true
      }
    }
  }
}
</script>

<style scoped>
.content1 {
  background-color: #fff;
  width: 100%;
}
.pingtai {
  width: 1;
  height: 100px;
  position: relative;
}
.herder {
  font-size: 30px;
  color: #71ae47;
  position: absolute;
  top: 38px;
  left: 190px;
}
.caidan {
  position: absolute;
  top: 80px;
  right: 50px;
}
.caidan div {
  display: inline;
  padding-left: 30px;
  color: #009966;
}
.el-menu-demo {
  display: flex;
  justify-content: center;
}
.el-menu-item {
  font-size: 20px;
  width: 200px;
  height: 50px;
}
.banner img {
  width: 100%;
  height: 600px;
}
.xinwen {
  width: 100%;
  height: 100px;
  position: relative;
}
.xinwen img {
  position: absolute;
  top: 50px;
  left: 200px;
}
.tablist {
  /* position: relative; */
  width: 1090px;
  height: 600px;
  margin: 0 auto;
}

.el-tab-pane {
  width: 150px;
  height: 50px;
}
.fenye {
  width: 420px;
  height: 35px;
  margin: 100px auto;
}
.page {
  width: 850px;
  height: 65px;
  padding-top: 30px;
  border-bottom: 1px solid #333;
}
.content {
  width: 900px;
  height: 800px;
}
.sanjiao {
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: dashed solid dashed dashed;
  border-color: transparent transparent transparent #e66161;
  float: left;
}
.banquan {
  position: relative;
}
.banquan img {
  width: 1500px;
  position: absolute;
  top: 880px;
}

.beijing {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  background-color: #52bb9d;
  background-size: 100% 100%;
  /* position: relative;
     top: 900px; */
}
.xinxi div {
  flex: 1;
}
.xinxi div img {
  float: left;
  margin-top: 3px;
}
.xinxi div p {
  float: left;
  line-height: 0px;
  color: #ffffff;
}
</style>
