<template>
  <div class="concent1">
    <Caidan></Caidan>
    <div class="banner">
      <img src="../assets/zu25.png"
           alt="">
    </div>
    <div class="xinwen">
      <img src="../assets/1.png"
           alt="">
    </div>
    <div class="tablist">
      <el-tabs class="list"
               :tab-position="tabPosition"
               @tab-click="changeTabPane"
               style="height: 640px;">
        <el-tab-pane class="content"
                     label="种植企业">
          <div class="page"
               v-for="item in companyList"
               :key="item.userId">
            <p class="sanjiao"></p>
            <p @click="tiaozhuan(item.userId)"
               style="float:left">{{item.nickName}}</p>
          </div>
          <div v-if="companyList.length==0"
               style="text-align: center;margin-top: 200px;">此分类下暂无企业</div>
        </el-tab-pane>
        <el-tab-pane class="content"
                     label="养殖企业">
          <div class="page"
               v-for="item in companyList"
               :key="item.userId">
            <p class="sanjiao"></p>
            <p @click="tiaozhuan(item.userId)"
               style="float:left">{{item.nickName}}</p>
          </div>
          <div v-if="companyList.length==0"
               style="text-align: center;margin-top: 200px;">此分类下暂无企业</div>
        </el-tab-pane>
        <el-tab-pane class="content"
                     label="加工企业">
          <div class="page"
               v-for="item in companyList"
               :key="item.userId">
            <p class="sanjiao"></p>
            <p @click="tiaozhuan(item.userId)"
               style="float:left">{{item.nickName}}</p>
          </div>
          <div v-if="companyList.length==0"
               style="text-align: center;margin-top: 200px;">此分类下暂无企业</div>
        </el-tab-pane>
        <el-tab-pane class="content"
                     label="屠宰企业">
          <div class="page"
               v-for="item in companyList"
               :key="item.userId">
            <p class="sanjiao"></p>
            <p @click="tiaozhuan(item.userId)"
               style="float:left">{{item.nickName}}</p>
          </div>
          <div v-if="companyList.length== 0"
               style="text-align: center;margin-top: 200px;">此分类下暂无企业</div>
        </el-tab-pane>
      </el-tabs>
      <div class="fenye"
           v-if="total!=0">
        <el-pagination @current-change="handleCurrentChange"
                       :current-page.sync="currentPage"
                       :page-size="5"
                       layout="total, prev, pager, next"
                       :total="total">
        </el-pagination>
      </div>
    </div>

    <div class="beijing">
      <div class="xinxi">
        <div>
          <img src="../assets/logo/1.png"
               alt="">
          <p style="font-size:12px;margin-left: 10px;">地址:山东省德州市临邑县临南镇</p>
        </div>
        <div>
          <img src="../assets/logo/2.png"
               alt="">
          <p style="font-size:12px;margin-left: 10px;">电话:0534-4710111</p>
        </div>
        <div>
          <img src="../assets/logo/3.png"
               alt="">
          <p style="font-size:12px;margin-left: 10px;">邮编:251500</p>
        </div>
        <div>
          <img src="../assets/logo/4.png"
               alt="">
          <p style="font-size:12px;margin-left: 10px;">邮箱:lnzrmzf@dz.shandong.cn</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      currentPage: 1,
      tabPosition: 'left',
      companyType: 'zhongzhi',
      companyList: [],
      total: 0
    };
  },
  mounted: function () {
    this.getshuju()
    //   this.current_change()
  },
  methods: {
    changeTabPane (e) {
      if (e.label == '种植企业') {
        this.companyType = 'zhongzhi'
      } else if (e.label == '养殖企业') {
        this.companyType = 'yangzhi'
      } else if (e.label == '加工企业') {
        this.companyType = 'jiagong'
      } else if (e.label == '屠宰企业') {
        this.companyType = 'tuzai'
      }
      this.getshuju()
    },
    getshuju () {
      const that = this
      this.$http.get('dmq/product/getCompanyList/' + this.companyType)
        .then(function (response) {
          that.companyList = response.data.rows
          that.total = response.data.total

        })
        .catch(function (error) {
          console.log(error, "出错了");
        });
    },
    tiaozhuan (id) {
      this.$router.push({ path: "/QiyeVal", query: { id } })
    },
    handleCurrentChange (val) {
      this.currentPage = val
      console.log(`当前页: ${val}`);
    }
  }
}
</script>

<style scoped>
.content1 {
  background-color: #fff;
  width: 100%;
}
.pingtai {
  width: 1;
  height: 100px;
  position: relative;
}
.herder {
  font-size: 30px;
  color: #71ae47;
  position: absolute;
  top: 38px;
  left: 190px;
}
.caidan {
  position: absolute;
  top: 80px;
  right: 50px;
}
.caidan div {
  display: inline;
  padding-left: 30px;
  color: #009966;
}
.el-menu-demo {
  display: flex;
  justify-content: center;
}
.el-menu-item {
  font-size: 20px;
  width: 200px;
  height: 50px;
}
.banner img {
  width: 100%;
  height: 600px;
}
.xinwen {
  width: 100%;
  height: 100px;
  position: relative;
}
.xinwen img {
  position: absolute;
  top: 50px;
  left: 200px;
}
.tablist {
  /* position: relative; */
  width: 1090px;
  height: 600px;
  margin: 0 auto;
}

.el-tab-pane {
  width: 150px;
  height: 50px;
}
.fenye {
  width: 420px;
  height: 35px;
  float: right;
}
.page {
  width: 850px;
  height: 65px;
  padding-top: 30px;
  border-bottom: 1px solid #333;
}
.content {
  width: 900px;
  height: 800px;
}
.sanjiao {
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: dashed solid dashed dashed;
  border-color: transparent transparent transparent #e66161;
  float: left;
}
.banquan {
  position: relative;
}
.banquan img {
  width: 1500px;
  position: absolute;
  top: 880px;
}

.beijing {
  width: 100%;
  height: 50px;
  /* background-image: url("../assets/image/165.png"); */
  background-color: #52bb9d;

  background-size: 100% 100%;
  display: flex;
  align-items: center;
  /* position: relative;
     top: 900px; */
}
/* .beijing .xinxi{
     width: 1000px;
     height: 25px;
     position: absolute;
     left: 16%;
     display: flex;
 } */
.xinxi div {
  flex: 1;
}
.xinxi div img {
  float: left;
  margin-top: 3px;
}
.xinxi div p {
  float: left;
  line-height: 0px;
  color: #ffffff;
}
/* .banquan{
     font-size: 12px;
     color: #ffffff;
     position: absolute;
     top: 20px;left: 43%;
 } */
</style>
