<template>
  <div>
    <!-- <Caidan></Caidan> -->
    <div class="banner">
      <img src="../assets/image/zu26.png" alt="">
    </div>
    <div class="fankui">
      <img src="../assets/image/32.png" alt="">
      <div class="jianyi">
        <div class="item">
          <span>姓名:</span>
          <el-input v-model="form.name" style="width:100%" />
        </div>
        <div class="item">
          <span>电话:</span>
          <el-input v-model="form.mobile" style="width:100%" />
        </div>
        <div class="item">
          <span>类型:</span>
          <el-select v-model="form.feedback_type" placeholder="请选择" style="width:100%;">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span>反馈内容:</span>
          <el-input style="width:100%;" type="textarea" :autosize="{ minRows: 4, maxRows: 4 }" placeholder="请输入内容"
            v-model="form.content">
          </el-input>
        </div>
        <div>
          <el-button @click="tijiao" pkain style="display:block;margin:20px auto" type="success">提交</el-button>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
import axios from 'axios'
import Qs from 'qs'
axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
axios.defaults.transformRequest = [obj => Qs.stringify(obj)]
export default {
  data() {
    return {
      textarea2: '',
      name: "",
      mobile: "",
      emile: "",
      yanzhengma: "",
      yanzhengma1: "",
      form: {
        name: "",
        mobile: "",
        feedback_type: "",
        content: "",
      },
      options: [],
    }
  },
  mounted: function () {
    this.getDict()
  },
  methods: {
    open2() {
      if (this.moblie != "undefined") {
        this.$http.post('dmq/zxjy/sendmes', {
          mobile: this.mobile
        }).then((res) => {
          if (res.code == 200) {
            this.yanzhengma1 = res.data
            this.$message({
              message: '发送成功',
              type: 'success'
            });
          } else {
            this.$message({
              message: '发送失败',
              type: 'error'
            });
          }
        }).catch(() => {
          this.$message({
            message: '发送失败',
            type: 'error'
          });
        })
      } else {
        console.log("123")
        this.$notify.error({
          title: '错误',
          message: '请输入手机号'
        });
      }


    },
    tijiao: function () {
      this.$http.post('/systems/feedback', { ...this.form }).then((res) => {
        if (res.status === 200) {
          this.form = {}
          this.$message({
            message: '提交成功！',
            type: 'success'
          });
        }
      })

    },

    //类型字典
    getDict() {
      let that = this
      that.$http.get('/system/dict/getByCode/' + 'FANKUITYPE')
        .then(function (response) {
          that.options = response.data.dict_values
        })
        .catch(function (error) {
          console.log(error, "出错");
        });
    },
  }
}
</script>

<style scoped>
.banner img {
  width: 1200px;
  max-height: 300px;
  display: block;
  margin: 0 auto;
}

.fankui {
  width: 1000px;
  height: 500px;
  position: relative;
  margin: 0 auto;
}

.fankui img {
  margin-top: 50px;
}

.jianyi {
  width: 600px;
  height: 400px;
  display: flex;
  margin-left: 100px;

  flex-direction: column;
}

.jianyi .item {
  display: flex;
  height: 100px;
  align-items: center;
}

.jianyi .item span {
  width: 100px;
  flex-shrink: 0;
}

.beijing {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  background-color: #52bb9d;
  position: relative;
}

.xinxi div {
  flex: 1;
}

.xinxi div img {
  float: left;
  margin-top: 3px;
}

.xinxi div p {
  float: left;
  line-height: 0px;
  color: #ffffff;
}
</style>