import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router/router1'
// import index from './router/index'
import './router/request'


import scroll from 'vue-seamless-scroll'
import Caidan from './components/caidan'
import Footer from './components/footer'

Vue.use(ElementUI);
Vue.use(scroll)
Vue.config.productionTip = false
Vue.component("Caidan", Caidan)
Vue.component("Footer", Footer)

new Vue({
  el: '#app',
  render: h => h(App),
  // index,
  router,
}).$mount('#app')
